import type { MealRateAdjustmentFree } from '@/property/meal/meal-rate-adjustment/free/meal-rate-adjustment-free';
import type { MealRateAdjustmentNone } from '@/property/meal/meal-rate-adjustment/none/meal-rate-adjustment-none';
import type { MealRateAdjustmentPerNightMonetary } from '@/property/meal/meal-rate-adjustment/per-night-monetary/meal-rate-adjustment-per-night-monetary';
import type { MealRateAdjustmentStandardRatePercentage } from '@/property/meal/meal-rate-adjustment/standard-rate-percentage/meal-rate-adjustment-standard-rate-percentage';

export enum MealRateAdjustmentType {
  None = 'none',
  Free = 'free',
  StandardRatePercentage = 'standard_rate_percentage',
  PerNightMonetary = 'per_night_monetary',
}

export type MealRateAdjustment =
  | MealRateAdjustmentFree
  | MealRateAdjustmentPerNightMonetary
  | MealRateAdjustmentStandardRatePercentage
  | MealRateAdjustmentNone;
