import type { PaymentGateway } from '@/property/payment-gateway/payment-gateway';
import { PaymentGatewayType } from '@/property/payment-gateway/payment-gateway';
import { mapStripeTransactionChargeFromDTO } from '@/property/payment-gateway/stripe/transaction-charge/stripe-transaction-charge.mapper';

export const mapPaymentGatewayFromDTO = (
  paymentGatewayDTO: any,
): PaymentGateway => {
  const { type } = paymentGatewayDTO;

  switch (type) {
    case PaymentGatewayType.None:
    case PaymentGatewayType.Pcib:
      return { type };
    case PaymentGatewayType.SagePay:
      return {
        type,
        canTakeDeposits: paymentGatewayDTO.canTakeDeposits,
      };
    case PaymentGatewayType.Stripe:
      return {
        type,
        isPreAuthEnabled: paymentGatewayDTO.isPreAuthEnabled,
        shouldDepositIncludeTaxes: paymentGatewayDTO.shouldDepositIncludeTaxes,
        publishableKey: paymentGatewayDTO.publishableKey,
        zeroDecimalCurrencyMultiplier:
          paymentGatewayDTO.zeroDecimalCurrencyMultiplier,
        transactionCharge: paymentGatewayDTO.transactionCharge
          ? mapStripeTransactionChargeFromDTO(
              paymentGatewayDTO.transactionCharge,
            )
          : undefined,
      };
    default:
      throw new Error(
        `Unexpected payment gateway type: ${JSON.stringify(type)}`,
      );
  }
};
