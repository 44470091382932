<template>
  <div v-if="isSmallerThanSm">
    <AppLinkButton
      :text="launchCodeInputButtonText"
      variant="basic"
      class="align-middle text-sm text-accent-contrast-color"
      @click="isCodeInputModalOpen = !isCodeInputModalOpen"
    >
      <template v-if="codeResourceStore.resource" #icon>
        <IconTag :size="20" />
      </template>
    </AppLinkButton>

    <AppModal
      :is-open="isCodeInputModalOpen"
      @close="isCodeInputModalOpen = false"
    >
      <AppModalDialog @close="isCodeInputModalOpen = false">
        <template #header>
          <CodeInputHeader />
        </template>

        <CodeInputBody
          :should-auto-focus-input="false"
          @close="isCodeInputModalOpen = false"
        />

        <template #footer>
          <CodeInputFooter
            @close="isCodeInputModalOpen = false"
            @clear="codeResourceStore.clear"
          />
        </template>
      </AppModalDialog>
    </AppModal>
  </div>

  <UseElementBounding v-else v-slot="{ right }">
    <AppPopover v-slot="{ close }">
      <PopoverButton as="template">
        <AppLinkButton
          :text="launchCodeInputButtonText"
          variant="basic"
          class="align-middle text-sm text-accent-contrast-color"
        >
          <template v-if="codeResourceStore.resource" #icon>
            <IconTag :size="20" />
          </template>
        </AppLinkButton>
      </PopoverButton>

      <div
        class="absolute top-full"
        :class="
          isSmallerThanMd
            ? 'right-0 flex justify-center'
            : 'translate-x-[-100%]'
        "
        :style="{ left: `${isSmallerThanMd ? 0 : right}px` }"
      >
        <AppPopoverPanel>
          <template #header>
            <CodeInputHeader />
          </template>

          <CodeInputBody should-auto-focus-input class="w-96" @close="close" />

          <template #footer>
            <CodeInputFooter @close="close" @clear="codeResourceStore.clear" />
          </template>
        </AppPopoverPanel>
      </div>
    </AppPopover>
  </UseElementBounding>
</template>

<script setup lang="ts">
import { PopoverButton } from '@headlessui/vue';
import { IconTag } from '@tabler/icons-vue';
import { UseElementBounding } from '@vueuse/components';
import { useBreakpoints, breakpointsTailwind, useEventBus } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { CodeInputStatusType } from '@/code/input/code-input-status';
import { useProvideCodeInputStore } from '@/code/input/code-input.store';
import CodeInputBody from '@/code/input/CodeInputBody.vue';
import CodeInputFooter from '@/code/input/CodeInputFooter.vue';
import CodeInputHeader from '@/code/input/CodeInputHeader.vue';
import { CodeResourceType } from '@/code/resource/code-resource';
import { useCodeResourceStore } from '@/code/resource/code-resource.store';
import { codeResourceRemoveEventBusKey } from '@/event-bus/event-bus';
import { useSearchStore } from '@/search/search.store';
import AppLinkButton from '@/ui/app-link-button/AppLinkButton.vue';
import AppModal from '@/ui/app-modal/AppModal.vue';
import AppModalDialog from '@/ui/app-modal/AppModalDialog.vue';
import AppPopover from '@/ui/app-popover/AppPopover.vue';
import AppPopoverPanel from '@/ui/app-popover/AppPopoverPanel.vue';

const { t } = useI18n();

const searchStore = useSearchStore();
const codeResourceStore = useCodeResourceStore();

const { activeProperty, activePropertyAvailability, stayDates } =
  storeToRefs(searchStore);

const launchCodeInputButtonText = computed(
  () => codeResourceStore.resource?.code ?? t('addPromocode'),
);

const breakpoints = useBreakpoints(breakpointsTailwind);
const isSmallerThanMd = breakpoints.smaller('md');
const isSmallerThanSm = breakpoints.smaller('sm');

const isCodeInputModalOpen = ref(false);

const { code, status, removeCode } = useProvideCodeInputStore({
  property: activeProperty,
  stayDates,
  propertyAvailability: activePropertyAvailability,
});

if (codeResourceStore.resource) {
  code.value = codeResourceStore.resource.code;

  status.value =
    codeResourceStore.resource.type === CodeResourceType.Promocode
      ? {
          type: CodeInputStatusType.Valid,
          resource: {
            type: CodeResourceType.Promocode,
            promocode: codeResourceStore.resource.promocode,
          },
        }
      : {
          type: CodeInputStatusType.Valid,
          resource: {
            type: CodeResourceType.PrivateRateOverride,
            privateRateOverride: codeResourceStore.resource.privateRateOverride,
          },
        };
}

watch(status, (status) => {
  switch (status.type) {
    case CodeInputStatusType.Valid:
      status.resource.type === CodeResourceType.Promocode
        ? codeResourceStore.setWithPromocode(
            code.value,
            status.resource.promocode,
          )
        : codeResourceStore.setWithPrivateRateOverride(
            code.value,
            status.resource.privateRateOverride,
          );

      break;
    case CodeInputStatusType.Invalid:
      codeResourceStore.clear();

      break;
  }
});

useEventBus(codeResourceRemoveEventBusKey).on(removeCode);
</script>
