import { mapBookingFromDTO } from '@/booking/booking.mapper';
import { BookingCreateResultType } from '@/booking/create/result/booking-create-result';
import type { BookingCreateConfirmedResult } from '@/booking/create/result/confirmed/booking-create-confirmed-result';

export const mapBookingCreateConfirmedResultFromDTO = (
  bookingCreateConfirmedResultDTO: any,
): BookingCreateConfirmedResult => ({
  type: BookingCreateResultType.Confirmed,
  booking: mapBookingFromDTO(bookingCreateConfirmedResultDTO.booking),
  accessToken: bookingCreateConfirmedResultDTO.accessToken,
});
