import { mapImageFromDTO } from '@/image/image.mapper';
import { mapOccupancyLimitsFromDTO } from '@/property/occupancy-limits/occupancy-limits.mapper';
import { mapPresetUnitFacilityFromDTO } from '@/property/unit/preset-unit-facility/preset-unit-facility.mapper';
import { mapUnitTypeFromDTO } from '@/property/unit/type/unit-type.mapper';
import type { Unit } from '@/property/unit/unit';
import { mapWayToSellFromDTO } from '@/property/way-to-sell/way-to-sell.mapper';

export const mapUnitFromDTO = (unitDTO: any): Unit => ({
  id: unitDTO.id,
  name: unitDTO.name,
  description: unitDTO.description,
  sizeSquareMeters: unitDTO.sizeSquareMeters,
  occupancyLimits: mapOccupancyLimitsFromDTO(unitDTO.occupancyLimits),
  waysToSell: unitDTO.waysToSell.map(mapWayToSellFromDTO),
  priorityOrderedWayToSellIds: unitDTO.priorityOrderedWayToSellIds,
  images: unitDTO.images.map(mapImageFromDTO),
  priorityOrderedImageIds: unitDTO.priorityOrderedImageIds,
  presetUnitFacilities: unitDTO.presetFacilities.map(
    mapPresetUnitFacilityFromDTO,
  ),
  customFacilityNames: unitDTO.customFacilityNames,
  hasNoIncludedMeals: unitDTO.hasNoIncludedMeals,
  mealServiceType: unitDTO.mealServiceType,
  hasTwinDoubleBedOption: unitDTO.hasTwinDoubleBedOption,
  type: mapUnitTypeFromDTO(unitDTO),
});
