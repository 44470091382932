import { OfferType } from '@/property/offer/offer';
import type { PackageOffer } from '@/property/offer/package-offer/package-offer';
import { mapPackageOfferNightlyUnitRateFromDTO } from '@/property/offer/package-offer/package-offer-nightly-rate/package-offer-nightly-unit-rate/package-offer-nightly-unit-rate.mapper';
import { mapPackageOfferNightlyWayToSellRateFromDTO } from '@/property/offer/package-offer/package-offer-nightly-rate/package-offer-nightly-way-to-sell-rate/package-offer-nightly-way-to-sell-rate.mapper';

export const mapPackageOfferFromDTO = (packageOfferDTO: any): PackageOffer => ({
  offerType: OfferType.Package,
  id: packageOfferDTO.id,
  name: packageOfferDTO.name,
  description: packageOfferDTO.description,
  fromDate: packageOfferDTO.fromDate,
  toDate: packageOfferDTO.toDate,
  applicableStayDaysType: packageOfferDTO.applicableStayDaysType,
  applicableDaysOfWeek: packageOfferDTO.applicableDaysOfWeek,
  minimumStayLengthDays: packageOfferDTO.minimumStayLengthDays,
  maximumStayLengthDays: packageOfferDTO.maximumStayLengthDays,
  minimumAdvanceBookingLengthDays:
    packageOfferDTO.minimumAdvanceBookingLengthDays,
  maximumAdvanceBookingLengthDays:
    packageOfferDTO.maximumAdvanceBookingLengthDays,
  nightlyUnitRates: packageOfferDTO.nightlyUnitRates.map(
    mapPackageOfferNightlyUnitRateFromDTO,
  ),
  nightlyWayToSellRates: packageOfferDTO.nightlyWayToSellRates.map(
    mapPackageOfferNightlyWayToSellRateFromDTO,
  ),
  includedMealTypes: packageOfferDTO.includedMealTypes,
});
