<template>
  <div data-test="BookingSummaryFooterTotalRate">
    {{
      $n(bookingItineraryStore.totalRate, {
        style: 'currency',
        currency: bookingItineraryStore.property!.currencyCode,
      })
    }}
  </div>
</template>
<script setup lang="ts">
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';

const bookingItineraryStore = useBookingItineraryStore();
</script>
