import { BookingCreateResultType } from '@/booking/create/result/booking-create-result';
import type { BookingCreateStripeActionRequiredResult } from '@/booking/create/result/stripe-action-required/booking-create-stripe-action-required';

export const mapBookingCreateStripeActionRequiredResultFromDTO = (
  bookingCreateStripeActionRequiredResultDTO: any,
): BookingCreateStripeActionRequiredResult => ({
  type: BookingCreateResultType.StripeActionRequired,
  bookingId: bookingCreateStripeActionRequiredResultDTO.bookingId,
  clientSecret: bookingCreateStripeActionRequiredResultDTO.clientSecret,
});
