import type {
  BaseOffer,
  WayToSellApplicableOffer,
  RefundableOffer,
  OfferType,
} from '@/property/offer/offer';

export enum FreeNightOfferType {
  Last = 'last',
  Cheapest = 'cheapest',
}

export interface FreeNightOffer
  extends BaseOffer,
    WayToSellApplicableOffer,
    RefundableOffer {
  readonly offerType: OfferType.FreeNight;
  freeNightType: FreeNightOfferType;
  numberOfFreeNights: number;
  applicableStayDurations: number[];
  applicableUnitIds: number[];
}
