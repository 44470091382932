import type { DepositPolicyMonetaryCharge } from '@/property/booking-policy/deposit-policy/charge/monetary/deposit-policy-monetary-charge';
import type { DepositPolicyNightlyCharge } from '@/property/booking-policy/deposit-policy/charge/nightly/deposit-policy-nightly-charge';
import type { DepositPolicyPerUnitMonetaryCharge } from '@/property/booking-policy/deposit-policy/charge/per-unit/deposit-policy-per-unit-monetary-charge';
import type { DepositPolicyPercentageCharge } from '@/property/booking-policy/deposit-policy/charge/percentage/deposit-policy-percentage-charge';

export enum DepositPolicyChargeType {
  Nightly = 'nightly',
  Percentage = 'percentage',
  Monetary = 'monetary',
  PerUnitMonetary = 'per_unit_monetary',
}

export type DepositPolicyCharge =
  | DepositPolicyMonetaryCharge
  | DepositPolicyPercentageCharge
  | DepositPolicyNightlyCharge
  | DepositPolicyPerUnitMonetaryCharge;
