<template>
  <ContainerLayout class="flex grow flex-col">
    <BookingStageViewHeader
      class="mx-2 mb-6 mt-10"
      :label="$t('guestDetails')"
    />

    <div class="grow space-x-5 md:mx-3 md:flex">
      <div class="flex-[2] p-4">
        <div class="mb-4">{{ $t('pleaseCompleteYourContactDetails') }}</div>
        <GuestDetailsForm />
      </div>

      <div v-if="!bookingSummaryStore.showModalVersion" class="flex-1">
        <div
          class="sticky top-[calc(theme(spacing.inline-booking-stage-selector-height)+theme(spacing.4))]"
        >
          <InlineBookingSummary />
        </div>
      </div>
    </div>
  </ContainerLayout>
</template>

<script setup lang="ts">
import { onActivated, onMounted } from 'vue';
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import BookingStageViewHeader from '@/booking-stage/view-header/BookingStageViewHeader.vue';
import { useBookingSummaryStore } from '@/booking-summary/booking-summary.store';
import InlineBookingSummary from '@/booking-summary/InlineBookingSummary.vue';
import { useElementsStore } from '@/elements/elements.store';
import GuestDetailsForm from '@/guest-details-page/form/GuestDetailsForm.vue';
import { useGuestDetailsStore } from '@/guest-details-page/guest-details.store';
import ContainerLayout from '@/layouts/ContainerLayout.vue';
import { FacebookPixelPageViewEventAction } from '@/property/facebook-pixel/facebook-pixel-event-action';
import { useFacebookPixelStore } from '@/property/facebook-pixel/facebook-pixel.store';
import { GoogleAnalyticsPageViewEventAction } from '@/property/google/analytics/google-analytics-event-action';
import { useGoogleAnalyticsStore } from '@/property/google/analytics/google-analytics.store';
import { GUEST_DETAILS_ROUTE } from '@/router';
import { useSearchStore } from '@/search/search.store';
import { UserEventActionLabel } from '@/user-event/user-event';
import { useUserEventStore } from '@/user-event/user-event.store';

const bookingSummaryStore = useBookingSummaryStore();
const bookingItineraryStore = useBookingItineraryStore();
const searchStore = useSearchStore();
const { watchNameChanges } = useGuestDetailsStore();
const elementsStore = useElementsStore();
const { trackUserEventAction } = useUserEventStore();
const { emitGAPageViewEvent } = useGoogleAnalyticsStore();
const { emitPixelPageViewEvent } = useFacebookPixelStore();

onActivated(() => {
  trackUserEventAction({
    label: UserEventActionLabel.PageView,
    metadata: {
      page_name: GUEST_DETAILS_ROUTE,
      widget_id: searchStore.widget.id,
      property_id: searchStore.activePropertyId,
      is_express_checkout: searchStore.activePropertyCanUseExpressCheckout,
      used_elements: elementsStore.usedElements,
    },
  });
  void emitGAPageViewEvent(
    GoogleAnalyticsPageViewEventAction.GuestDetailsPage,
    searchStore.activeProperty.googleAnalytics,
    bookingItineraryStore.stayDates,
  );
  emitPixelPageViewEvent(
    FacebookPixelPageViewEventAction.GuestDetailsPage,
    searchStore.primaryProperty.facebookPixel,
    bookingItineraryStore.stayDates,
  );
});

onMounted(() => {
  watchNameChanges();
});
</script>
