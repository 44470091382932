import { mapBookingPolicyFromDTO } from '@/property/booking-policy/booking-policy.mapper';
import type { DateRangedBookingPolicy } from '@/property/booking-policy/date-ranged-booking-policy/date-ranged-booking-policy';
import { mapDateRangedBookingPolicyDateRangeFromDTO } from '@/property/booking-policy/date-ranged-booking-policy/date-ranged-booking-policy-date-range/date-ranged-booking-policy-date-range.mapper';

export const mapDateRangedBookingPolicyFromDTO = (
  dateRangedBookingPolicyDTO: any,
): DateRangedBookingPolicy => ({
  dateRanges: dateRangedBookingPolicyDTO.dateRanges.map(
    mapDateRangedBookingPolicyDateRangeFromDTO,
  ),
  bookingPolicy: mapBookingPolicyFromDTO(
    dateRangedBookingPolicyDTO.bookingPolicy,
  ),
});
