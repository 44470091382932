import { mapBookingAdHocSupplementFromDTO } from '@/booking/ad-hoc-supplement/booking-ad-hoc-supplement.mapper';
import type { Booking } from '@/booking/booking';
import { mapBookingTaxesFromDTO } from '@/booking/booking-taxes/booking-taxes.mapper';
import { mapBookingExtraFromDTO } from '@/booking/extra/booking-extra.mapper';
import { mapBookingGuestFromDTO } from '@/booking/guest/booking-guest.mapper';
import { mapBookingPaymentFromDTO } from '@/booking/payment/booking-payment.mapper';
import { mapBookingPrivateRateOverrideFromDTO } from '@/booking/private-rate-override/booking-private-rate-override.mapper';
import {
  mapActiveBookingUnitFromDTO,
  mapActiveBookingUnitToUpdateDTO,
} from '@/booking/unit/active/active-booking-unit.mapper';
import { mapCancelledBookingUnitFromDTO } from '@/booking/unit/cancelled/cancelled-booking-unit.mapper';
import { mapBookingPolicyFromDTO } from '@/property/booking-policy/booking-policy.mapper';
import { mapPromocodeFromDTO } from '@/property/promocode/promocode.mapper';
import { mapPropertyFromDTO } from '@/property/property.mapper';

export const mapBookingFromDTO = (bookingDTO: any): Booking => ({
  id: bookingDTO.id,
  prefix: bookingDTO.prefix,
  checkInDate: bookingDTO.checkInDate,
  checkOutDate: bookingDTO.checkOutDate,
  arrivalTime: bookingDTO.arrivalTime ?? undefined,
  specialRequests: bookingDTO.specialRequests,
  locale: bookingDTO.locale,
  widgetId: bookingDTO.widgetId,
  property: mapPropertyFromDTO(bookingDTO.property),
  activeBookingUnits: bookingDTO.activeBookingUnits.map(
    mapActiveBookingUnitFromDTO,
  ),
  cancelledBookingUnits: bookingDTO.cancelledBookingUnits.map(
    mapCancelledBookingUnitFromDTO,
  ),
  bookingExtras: bookingDTO.bookingExtras.map(mapBookingExtraFromDTO),
  bookingAdHocSupplements: bookingDTO.bookingAdHocSupplements.map(
    mapBookingAdHocSupplementFromDTO,
  ),
  bookingGuest: mapBookingGuestFromDTO(bookingDTO.bookingGuest),
  bookingPolicy: mapBookingPolicyFromDTO(bookingDTO.bookingPolicy),
  bookingTaxes: mapBookingTaxesFromDTO(bookingDTO.bookingTaxes),
  bookingPayments: bookingDTO.bookingPayments.map(mapBookingPaymentFromDTO),
  promocode: bookingDTO.promocode
    ? mapPromocodeFromDTO(bookingDTO.promocode)
    : undefined,
  bookingPrivateRateOverride: bookingDTO.bookingPrivateRateOverride
    ? mapBookingPrivateRateOverrideFromDTO(
        bookingDTO.bookingPrivateRateOverride,
      )
    : undefined,
  totalAmount: Number(bookingDTO.totalAmount),
  totalAmountBeforeTax: Number(bookingDTO.totalAmountBeforeTax),
  amountPaid: Number(bookingDTO.amountPaid),
  amountDue: Number(bookingDTO.amountDue),
  isCancelled: bookingDTO.isCancelled,
  isAnyActiveBookingUnitNonRefundable:
    bookingDTO.isAnyActiveBookingUnitNonRefundable,
  depositAmount: Number(bookingDTO.depositAmount),
  guestCanStartMessagingConversation:
    bookingDTO.guestCanStartMessagingConversation,
  usedExpressCheckout: bookingDTO.usedExpressCheckout,
  usedElements: bookingDTO.usedElements,
  hasBeenModifiedByGuest: bookingDTO.hasBeenModifiedByGuest,
  creationDateTime: bookingDTO.creationDateTime,
});

export const mapBookingToUpdateDTO = (booking: Booking) => ({
  arrivalTime: booking.arrivalTime ?? null,
  specialRequests: booking.specialRequests ?? null,
  units: booking.activeBookingUnits.map(mapActiveBookingUnitToUpdateDTO),
});
