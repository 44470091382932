import type { BookingAdHocSupplement } from '@/booking/ad-hoc-supplement/booking-ad-hoc-supplement';
import type { BookingTaxes } from '@/booking/booking-taxes/booking-taxes';
import type { BookingExtra } from '@/booking/extra/booking-extra';
import type { BookingGuest } from '@/booking/guest/booking-guest';
import type { BookingPayment } from '@/booking/payment/booking-payment';
import type { BookingPrivateRateOverride } from '@/booking/private-rate-override/booking-private-rate-override';
import type { ActiveBookingUnit } from '@/booking/unit/active/active-booking-unit';
import type { CancelledBookingUnit } from '@/booking/unit/cancelled/cancelled-booking-unit';
import type { BookingPolicy } from '@/property/booking-policy/booking-policy';
import type { Promocode } from '@/property/promocode/promocode';
import type { Property } from '@/property/property';
import type { Time } from '@/time/time';

export interface Booking {
  id: string;
  prefix: string;
  checkInDate: string;
  checkOutDate: string;
  arrivalTime: Time | undefined;
  specialRequests: string | undefined;
  locale: string;
  widgetId: string;
  property: Property;
  activeBookingUnits: ActiveBookingUnit[];
  cancelledBookingUnits: CancelledBookingUnit[];
  bookingExtras: BookingExtra[];
  bookingAdHocSupplements: BookingAdHocSupplement[];
  bookingGuest: BookingGuest;
  bookingPolicy: BookingPolicy;
  bookingTaxes: BookingTaxes;
  bookingPayments: BookingPayment[];
  promocode: Promocode | undefined;
  bookingPrivateRateOverride: BookingPrivateRateOverride | undefined;
  totalAmount: number;
  totalAmountBeforeTax: number;
  amountPaid: number;
  amountDue: number;
  isCancelled: boolean;
  isAnyActiveBookingUnitNonRefundable: boolean;
  depositAmount: number;
  guestCanStartMessagingConversation: boolean;
  usedExpressCheckout: boolean;
  usedElements: boolean;
  hasBeenModifiedByGuest: boolean;
  creationDateTime: string;
}

export const FIRST_NAME_MAX_LENGTH = 20;
export const SURNAME_MAX_LENGTH = 64;
export const EMAIL_MAX_LENGTH = 128;
export const PHONE_NUMBER_MAX_LENGTH = 20;
export const ADDRESS_STREET_MAX_LENGTH = 30;
export const ADDRESS_CITY_MAX_LENGTH = 25;
export const ADDRESS_POSTCODE_MAX_LENGTH = 20;
export const ADDRESS_ADMINISTRATIVE_AREA_MAX_LENGTH = 25;
export const LEAD_GUEST_NAME_MAX_LENGTH = 97;
export const SPECIAL_REQUESTS_MAX_LENGTH = 255;
