import { PaymentMethodType } from '@/payment-method/payment-method';
import { mapCardPaymentMethodPolicyFromDTO } from '@/property/booking-policy/payment-method-policy/card/card-payment-method-policy.mapper';
import type { PaymentMethodPolicy } from '@/property/booking-policy/payment-method-policy/payment-method-policy';

export const mapPaymentMethodPolicyFromDTO = (
  paymentMethodPolicyDTO: any,
): PaymentMethodPolicy => {
  const { paymentMethodType } = paymentMethodPolicyDTO;

  switch (paymentMethodType) {
    case PaymentMethodType.Card:
      return mapCardPaymentMethodPolicyFromDTO(paymentMethodPolicyDTO);
    case PaymentMethodType.Account:
    case PaymentMethodType.BACS:
    case PaymentMethodType.Cash:
    case PaymentMethodType.Cheque:
    case PaymentMethodType.Paypal:
    case PaymentMethodType.Voucher:
      return { paymentMethodType };
    default:
      throw new Error(
        `Unexpected payment method type: ${JSON.stringify(paymentMethodType)}`,
      );
  }
};
