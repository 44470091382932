import type { GroupsPolicy } from '@/property/groups-policy/groups-policy';
import { GroupsPolicyType } from '@/property/groups-policy/groups-policy';

export const mapGroupsPolicyFromDTO = (groupsPolicyDTO: any): GroupsPolicy => {
  const { policyType } = groupsPolicyDTO;

  switch (policyType) {
    case GroupsPolicyType.All:
      return { policyType };
    case GroupsPolicyType.Limited:
      return {
        policyType,
        maximumGroupSize: groupsPolicyDTO.maximumGroupSize ?? undefined,
        acceptsHenAndStagGroups: groupsPolicyDTO.acceptsHenAndStagGroups,
      };
    default:
      throw new Error(
        `Unexpected groups policy type: ${JSON.stringify(policyType)}`,
      );
  }
};
