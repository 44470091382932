import type { CheckInPolicy } from '@/property/check-in-policy/check-in-policy';

export const mapCheckInPolicyFromDTO = (
  checkInPolicyDTO: any,
): CheckInPolicy => ({
  earliestCheckInTime: checkInPolicyDTO.earliestCheckInTime,
  latestCheckInTime: checkInPolicyDTO.latestCheckInTime,
  earliestCheckOutTime: checkInPolicyDTO.earliestCheckOutTime,
  latestCheckOutTime: checkInPolicyDTO.latestCheckOutTime,
  checkInNotes: checkInPolicyDTO.checkInNotes,
});
