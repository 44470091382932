import type { Extra } from '@/property/extra/extra';
import { mapExtraTaxFromDTO } from '@/property/extra/tax/extra-tax.mapper';

export const mapExtraFromDTO = (extraDTO: any): Extra => ({
  id: extraDTO.id,
  name: extraDTO.name,
  description: extraDTO.description,
  charge: Number(extraDTO.charge),
  chargeType: extraDTO.chargeType,
  isMandatory: extraDTO.isMandatory,
  question: extraDTO.question,
  dateRange: extraDTO.dateRange
    ? {
        fromDate: extraDTO.dateRange.fromDate,
        toDate: extraDTO.dateRange.toDate,
      }
    : undefined,
  tax: extraDTO.tax ? mapExtraTaxFromDTO(extraDTO.tax) : undefined,
});
