import type { BookingPayment } from './booking-payment';

export const mapBookingPaymentFromDTO = (
  bookingPaymentDTO: any,
): BookingPayment => ({
  payerName: bookingPaymentDTO.payerName,
  amount: bookingPaymentDTO.amount,
  cardChargeAmount: bookingPaymentDTO.cardChargeAmount ?? undefined,
  date: bookingPaymentDTO.date,
  isRefund: bookingPaymentDTO.isRefund,
});
