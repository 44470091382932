import { PaymentMethodType } from '@/payment-method/payment-method';
import type { CardPaymentMethodPolicy } from '@/property/booking-policy/payment-method-policy/card/card-payment-method-policy';
import { mapCardPaymentMethodPolicyCardFromDTO } from '@/property/booking-policy/payment-method-policy/card/card-payment-method-policy-card.mapper';

export const mapCardPaymentMethodPolicyFromDTO = (
  cardPaymentMethodPolicyDTO: any,
): CardPaymentMethodPolicy => ({
  paymentMethodType: PaymentMethodType.Card,
  cards: cardPaymentMethodPolicyDTO.cards.map(
    mapCardPaymentMethodPolicyCardFromDTO,
  ),
});
