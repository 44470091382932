import { mapParkingPolicyChargeFromDTO } from '@/property/parking-policy/charge/parking-policy-charge.mapper';
import type { ParkingPolicies } from '@/property/parking-policy/parking-policies';

export const mapParkingPoliciesFromDTO = (
  parkingPoliciesDTO: any,
): ParkingPolicies => ({
  onSiteParkingPolicy: parkingPoliciesDTO.onSiteParkingPolicy
    ? {
        charge: mapParkingPolicyChargeFromDTO(
          parkingPoliciesDTO.onSiteParkingPolicy.charge,
        ),
        isEnoughForAllGuests:
          parkingPoliciesDTO.onSiteParkingPolicy.isEnoughForAllGuests,
      }
    : undefined,
  streetParkingPolicy: parkingPoliciesDTO.streetParkingPolicy
    ? {
        charge: mapParkingPolicyChargeFromDTO(
          parkingPoliciesDTO.streetParkingPolicy.charge,
        ),
      }
    : undefined,
  nearbyParkingPolicy: parkingPoliciesDTO.nearbyParkingPolicy
    ? {
        charge: mapParkingPolicyChargeFromDTO(
          parkingPoliciesDTO.nearbyParkingPolicy.charge,
        ),
      }
    : undefined,
});
