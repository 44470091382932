import type { FreeNightOffer } from '@/property/offer/free-night-offer/free-night-offer';
import { OfferType } from '@/property/offer/offer';

export const mapFreeNightOfferFromDTO = (
  freeNightOfferDTO: any,
): FreeNightOffer => ({
  offerType: OfferType.FreeNight,
  id: freeNightOfferDTO.id,
  freeNightType: freeNightOfferDTO.freeNightType,
  name: freeNightOfferDTO.name,
  fromDate: freeNightOfferDTO.fromDate,
  toDate: freeNightOfferDTO.toDate,
  applicableStayDaysType: freeNightOfferDTO.applicableStayDaysType,
  applicableDaysOfWeek: freeNightOfferDTO.applicableDaysOfWeek,
  isRefundable: freeNightOfferDTO.isRefundable,
  applicableWayToSellIds: freeNightOfferDTO.applicableWayToSellIds,
  numberOfFreeNights: freeNightOfferDTO.numberOfFreeNights,
  applicableStayDurations: freeNightOfferDTO.applicableStayDurations,
  applicableUnitIds: freeNightOfferDTO.applicableUnitIds,
});
