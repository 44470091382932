<template>
  <div v-if="extraItineraryItemStore.quantity > 0" class="whitespace-nowrap">
    {{
      $n(extraItineraryItemStore.totalRate, {
        style: 'currency',
        currency: bookingItineraryStore.property!.currencyCode,
      })
    }}
  </div>
</template>
<script setup lang="ts">
import { useExtraItineraryItemStore } from '@/booking-itinerary/extra-itinerary/store/extra-itinerary-item.store';
import { useMandatoryExtraItineraryItemStore } from '@/booking-itinerary/extra-itinerary/store/mandatory-extra-itinerary-item.store';
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import type { Extra } from '@/property/extra/extra';

const props = defineProps<{
  extra: Extra;
}>();

const bookingItineraryStore = useBookingItineraryStore();
const extraItineraryItemStore = props.extra.isMandatory
  ? useMandatoryExtraItineraryItemStore(props.extra)
  : useExtraItineraryItemStore(props.extra);
</script>
