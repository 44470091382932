import type { BookingPolicies } from '@/property/booking-policy/booking-policies/booking-policies';
import { mapDateRangedBookingPolicyFromDTO } from '@/property/booking-policy/date-ranged-booking-policy/date-ranged-booking-policy.mapper';
import { mapStandardBookingPolicyFromDTO } from '@/property/booking-policy/standard-booking-policy/standard-booking-policy.mapper';

export const mapBookingPoliciesFromDTO = ({
  standardBookingPolicies,
  dateRangedBookingPolicies,
}: any): BookingPolicies => ({
  standardBookingPolicies: standardBookingPolicies.map(
    mapStandardBookingPolicyFromDTO,
  ),
  dateRangedBookingPolicies: dateRangedBookingPolicies.map(
    mapDateRangedBookingPolicyFromDTO,
  ),
});
