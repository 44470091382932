import type { UnitType } from '@/property/unit/type/unit-type';
import {
  translatableUnitTypeNames,
  createKnownUnitTypeFromTranslatableUnitTypeName,
} from '@/property/unit/type/unit-type.utilities';

export const mapUnitTypeFromDTO = (unitDTO: any): UnitType => {
  const translatableUnitTypeName = translatableUnitTypeNames.find(
    (translatableUnitTypeName) => translatableUnitTypeName === unitDTO.type,
  );

  return translatableUnitTypeName
    ? createKnownUnitTypeFromTranslatableUnitTypeName(translatableUnitTypeName)
    : {
        isKnown: false,
        name: unitDTO.type,
      };
};
