import type { OccupancyLimits } from '@/property/occupancy-limits/occupancy-limits';

export const mapOccupancyLimitsFromDTO = (
  occupancyLimitsDTO: any,
): OccupancyLimits => ({
  maximumNumberOfAdults: occupancyLimitsDTO.maximumNumberOfAdults,
  maximumNumberOfNonInfantChildren:
    occupancyLimitsDTO.maximumNumberOfNonInfantChildren,
  maximumNumberOfInfantChildren:
    occupancyLimitsDTO.maximumNumberOfInfantChildren,
});
