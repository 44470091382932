import type {
  Stripe,
  StripeElementLocale,
  StripeElements,
} from '@stripe/stripe-js';
import { Decimal } from 'decimal.js';
import { defineStore } from 'pinia';
import { computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import { useGuestStore } from '@/guest/guest.store';
import { PaymentGatewayType } from '@/property/payment-gateway/payment-gateway';
import { StripeTransactionChargeType } from '@/property/payment-gateway/stripe/transaction-charge/stripe-transaction-charge';
import {
  generateStripeElementsOptions,
  generateStripeExpressCheckoutOptions,
  generateStripePaymentElementOptions,
  generateStripePaymentMethodOptions,
} from '@/stripe-elements/options/stripe-elements-options.utilities';
import { loadStripe } from '@/stripe-elements/stripe-elements.utilities';

export let stripe: Stripe;
export let elements: StripeElements;

export const useStripeElementsStore = defineStore('stripe-elements', () => {
  const { locale } = useI18n();

  const bookingItineraryStore = useBookingItineraryStore();
  const guestStore = useGuestStore();

  const stripePaymentGateway = computed(() => {
    const { paymentGateway } = bookingItineraryStore.property;

    if (paymentGateway.type !== PaymentGatewayType.Stripe) {
      throw new Error("Property's payment gateway was not Stripe");
    }

    return paymentGateway;
  });

  const transactionChargeAmount = computed(() => {
    const { transactionCharge } = stripePaymentGateway.value;

    if (!transactionCharge) {
      return 0;
    }

    return transactionCharge.type === StripeTransactionChargeType.Monetary
      ? transactionCharge.amount
      : Decimal.mul(
          bookingItineraryStore.depositAmount,
          transactionCharge.percentage,
        )
          .dividedBy(100)
          .toNumber();
  });

  const zeroDecimalDepositAmount = computed(() =>
    Decimal.add(
      bookingItineraryStore.depositAmount,
      transactionChargeAmount.value,
    )
      .mul(stripePaymentGateway.value.zeroDecimalCurrencyMultiplier)
      .round()
      .toNumber(),
  );

  const initializeStripe = async (publishableKey: string) => {
    stripe = await loadStripe(
      publishableKey,
      locale.value as StripeElementLocale,
    );
  };

  const initializeElements = () => {
    elements = stripe.elements(
      generateStripeElementsOptions(
        zeroDecimalDepositAmount.value,
        bookingItineraryStore.property.currencyCode.toLowerCase(),
      ),
    );
  };

  const initialize = async (publishableKey: string) => {
    await initializeStripe(publishableKey);

    initializeElements();
  };

  const generatePaymentElementOptions = () =>
    generateStripePaymentElementOptions(
      guestStore.guest.emailAddress,
      guestStore.guest.address.postcode,
      guestStore.guest.address.countryCode,
    );

  const generatePaymentMethodOptions = () =>
    generateStripePaymentMethodOptions(elements, guestStore.name);

  watch(locale, (locale) => {
    elements.update({ locale: locale as StripeElementLocale });
  });

  watch(zeroDecimalDepositAmount, (zeroDecimalDepositAmount) => {
    elements.update({
      mode: zeroDecimalDepositAmount > 0 ? 'payment' : 'setup',
      amount: zeroDecimalDepositAmount,
    });
  });

  return {
    initialize,
    generatePaymentElementOptions,
    generateExpressCheckoutElementOptions: generateStripeExpressCheckoutOptions,
    generatePaymentMethodOptions,
  };
});
