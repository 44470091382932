<template>
  <div v-if="checkInNotes">
    <span class="font-medium capitalize">{{ $t('notes') }}:</span>
    {{ checkInNotes }}
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';

const bookingItineraryStore = useBookingItineraryStore();

const checkInNotes = computed(
  () => bookingItineraryStore.property.checkInPolicy.checkInNotes,
);
</script>
