import type { ParkingPolicyCharge } from '@/property/parking-policy/charge/parking-policy-charge';
import { ParkingPolicyChargeType } from '@/property/parking-policy/charge/parking-policy-charge';

export const mapParkingPolicyChargeFromDTO = (
  parkingPolicyChargeDTO: any,
): ParkingPolicyCharge => {
  const { chargeType } = parkingPolicyChargeDTO;

  switch (chargeType) {
    case ParkingPolicyChargeType.PerDayMonetary:
      return {
        chargeType,
        amountPerDay: Number(parkingPolicyChargeDTO.amountPerDay),
      };
    case ParkingPolicyChargeType.Free:
      return { chargeType };
    default:
      throw new Error(
        `Unexpected parking policy charge type: ${JSON.stringify(chargeType)}`,
      );
  }
};
