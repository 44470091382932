import type { BookingOffer } from '@/booking/offer/booking-offer';
import type { BookingUnit } from '@/booking/unit/booking-unit';
import type { BookingUnitMeal } from '@/booking/unit/meal/booking-unit-meal';
import type { BookingWayToSell } from '@/booking/way-to-sell/booking-way-to-sell';
import type { Occupancy } from '@/occupancy/occupancy';

export enum TwinDoubleBedOption {
  Twin = 'twin',
  Double = 'double',
}

export interface ActiveBookingUnit extends BookingUnit {
  rate: number;
  bookingUnitWayToSell: BookingWayToSell | undefined;
  bookingUnitOffer: BookingOffer | undefined;
  occupancy: Occupancy;
  bookingUnitMeals: BookingUnitMeal[];
  twinDoubleBedOption: TwinDoubleBedOption | undefined;
  leadGuestName: string | undefined;
  isNonRefundable: boolean;
}
