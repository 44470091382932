export enum FreetobookMessageEventDataType {
  SagePay3DSSuccess = 'sage_pay_3ds_success',
  SagePay3DSFailure = 'sage_pay_3ds_failure',
}

export interface FreetobookMessageEventSagePay3DSSuccessData {
  type: FreetobookMessageEventDataType.SagePay3DSSuccess;
  booking: object;
  accessToken: string;
}

export interface FreetobookMessageEventSagePay3DSFailureData {
  type: FreetobookMessageEventDataType.SagePay3DSFailure;
  sagePayCode: string;
}

type FreetobookMessageEventData =
  | FreetobookMessageEventSagePay3DSSuccessData
  | FreetobookMessageEventSagePay3DSFailureData;

export type FreetobookMessageEvent = MessageEvent<FreetobookMessageEventData>;
