export const PRESET_UNIT_FACILITY_ID_BATH_ENSUITE = 2;
export const PRESET_UNIT_FACILITY_ID_BED_LINEN_CHARGE = 3;
export const PRESET_UNIT_FACILITY_ID_BUNK_BEDS = 4;
export const PRESET_UNIT_FACILITY_ID_CD_PLAYER = 5;
export const PRESET_UNIT_FACILITY_ID_DISHWASHER = 6;
export const PRESET_UNIT_FACILITY_ID_COOKER = 7;
export const PRESET_UNIT_FACILITY_ID_ENSUITE_BATH_SHOWER_ROOM_IN_UNIT = 8;
export const PRESET_UNIT_FACILITY_ID_EXTRA_LINEN_ON_REQUEST = 9;
export const PRESET_UNIT_FACILITY_ID_FOUR_POSTER_BED = 10;
export const PRESET_UNIT_FACILITY_ID_FREEZER = 11;
export const PRESET_UNIT_FACILITY_ID_FRIDGE = 12;
export const PRESET_UNIT_FACILITY_ID_HAIR_DRYER_IN_ROOM = 13;
export const PRESET_UNIT_FACILITY_ID_HEATING_THROUGHOUT_PROPERTY = 14;
export const PRESET_UNIT_FACILITY_ID_IRONING_FACILITIES = 15;
export const PRESET_UNIT_FACILITY_ID_KING_SIZE_BED = 16;
export const PRESET_UNIT_FACILITY_ID_KITCHEN = 17;
export const PRESET_UNIT_FACILITY_ID_LINEN_TOWELS_SUPPLIED = 18;
export const PRESET_UNIT_FACILITY_ID_LINEN_AVAILABLE_CHARGE_APPLIES = 19;
export const PRESET_UNIT_FACILITY_ID_MICROWAVE = 20;
export const PRESET_UNIT_FACILITY_ID_MINI_BAR = 21;
export const PRESET_UNIT_FACILITY_ID_MODEM_POINT_AVAILABLE = 22;
export const PRESET_UNIT_FACILITY_ID_PRIVATE_BATHROOM = 23;
export const PRESET_UNIT_FACILITY_ID_RADIO = 24;
export const PRESET_UNIT_FACILITY_ID_SATELLITE_CABLE_TELEVISION = 25;
export const PRESET_UNIT_FACILITY_ID_SEA_LOCH_VIEW = 26;
export const PRESET_UNIT_FACILITY_ID_SHARED_BATHROOM = 27;
export const PRESET_UNIT_FACILITY_ID_SHOWER_ENSUITE = 28;
export const PRESET_UNIT_FACILITY_ID_SPA_BATH_ENSUITE = 29;
export const PRESET_UNIT_FACILITY_ID_TV_IN_ROOM = 30;
export const PRESET_UNIT_FACILITY_ID_TELEPHONE_IN_ROOM = 31;
export const PRESET_UNIT_FACILITY_ID_TROUSER_PRESS = 32;
export const PRESET_UNIT_FACILITY_ID_TUMBLE_DRYER = 33;
export const PRESET_UNIT_FACILITY_ID_USE_OF_OWN_BED_LINEN_REQUIRED = 34;
export const PRESET_UNIT_FACILITY_ID_WC_ENSUITE = 35;
export const PRESET_UNIT_FACILITY_ID_WASH_HAND_BASIN_ENSUITE = 36;
export const PRESET_UNIT_FACILITY_ID_WASHING_MACHINE = 37;
export const PRESET_UNIT_FACILITY_ID_BALCONY = 38;
export const PRESET_UNIT_FACILITY_ID_BATHROBES = 39;
export const PRESET_UNIT_FACILITY_ID_BILL_MESSAGE_VIEWING = 40;
export const PRESET_UNIT_FACILITY_ID_BROADBAND_HIGH_SPEED_INTERNET_ACCESS = 41;
export const PRESET_UNIT_FACILITY_ID_CHOICE_OF_PILLOWS = 42;
export const PRESET_UNIT_FACILITY_ID_COMPLIMENTARY_TOILETRIES = 43;
export const PRESET_UNIT_FACILITY_ID_CONNECTING_ROOMS = 44;
export const PRESET_UNIT_FACILITY_ID_DINING_AREA = 45;
export const PRESET_UNIT_FACILITY_ID_GAMES_CONSOLE = 46;
export const PRESET_UNIT_FACILITY_ID_I_POD_DOCKING_STATION = 47;
export const PRESET_UNIT_FACILITY_ID_IN_HOUSE_MOVIE = 48;
export const PRESET_UNIT_FACILITY_ID_IN_ROOM_VIDEO_CHECK_OUT = 49;
export const PRESET_UNIT_FACILITY_ID_INTERNET_VIA_TV = 50;
export const PRESET_UNIT_FACILITY_ID_KITCHENETTE = 51;
export const PRESET_UNIT_FACILITY_ID_LAPTOP_SAFE = 52;
export const PRESET_UNIT_FACILITY_ID_LCD_PLASMA_TELEVISION = 53;
export const PRESET_UNIT_FACILITY_ID_LIVING_ROOM = 54;
export const PRESET_UNIT_FACILITY_ID_MIST_FREE_MIRROR = 55;
export const PRESET_UNIT_FACILITY_ID_PC_IN_ROOM = 56;
export const PRESET_UNIT_FACILITY_ID_PERSONAL_SAFE = 57;
export const PRESET_UNIT_FACILITY_ID_SOUNDPROOFED_WINDOWS = 58;
export const PRESET_UNIT_FACILITY_ID_TEA_COFFEE = 59;
export const PRESET_UNIT_FACILITY_ID_TELEPHONE_WITH_VOICEMAIL = 60;
export const PRESET_UNIT_FACILITY_ID_WIFI_INTERNET_ACCESS = 61;
export const PRESET_UNIT_FACILITY_ID_WORK_DESK = 62;
export const PRESET_UNIT_FACILITY_ID_AIR_CONDITIONED = 63;
export const PRESET_UNIT_FACILITY_ID_ALARM_CLOCK = 64;
export const PRESET_UNIT_FACILITY_ID_ALL_BEDROOMS_NON_SMOKING = 65;
export const PRESET_UNIT_FACILITY_ID_ALL_ROOMS_NON_SMOKING = 66;
export const PRESET_UNIT_FACILITY_ID_AUTOMATIC_WAKE_UP_CALL = 67;
export const PRESET_UNIT_FACILITY_ID_HAIRDRYER = 68;
export const PRESET_UNIT_FACILITY_ID_DVD_PLAYER = 69;
export const PRESET_UNIT_FACILITY_ID_FRIDGE_FREEZER = 70;
export const PRESET_UNIT_FACILITY_ID_BATH_TUB = 71;
export const PRESET_UNIT_FACILITY_ID_SHOWER_ROOM = 72;
export const PRESET_UNIT_FACILITY_ID_TELEVISION = 73;
export const PRESET_UNIT_FACILITY_ID_ELECTRIC_SHAVER_POINT = 74;
export const PRESET_UNIT_FACILITY_ID_BATH_SHOWER = 75;
export const PRESET_UNIT_FACILITY_ID_MULTI_FUEL_WOOD_BURNING_STOVE = 76;
export const PRESET_UNIT_FACILITY_ID_LOG_FIRE = 77;
export const PRESET_UNIT_FACILITY_ID_COIN_PAYPHONE = 78;
export const PRESET_UNIT_FACILITY_ID_SAUNA = 79;
export const PRESET_UNIT_FACILITY_ID_PRIVATE_CAR_SPACE = 80;
export const PRESET_UNIT_FACILITY_ID_COASTAL_LOCATION = 81;
export const PRESET_UNIT_FACILITY_ID_JACUZZI = 82;
export const PRESET_UNIT_FACILITY_ID_CERAMIC_HOB = 83;
export const PRESET_UNIT_FACILITY_ID_COFFEE_MAKER = 84;
export const PRESET_UNIT_FACILITY_ID_UNDERFLOOR_HEATING = 85;
export const PRESET_UNIT_FACILITY_ID_TELEPHONE = 86;
export const PRESET_UNIT_FACILITY_ID_CENTRAL_HEATING = 87;
export const PRESET_UNIT_FACILITY_ID_TWIN_DOUBLE_CHANGEABLE = 88;
export const PRESET_UNIT_FACILITY_ID_DUVET = 89;
export const PRESET_UNIT_FACILITY_ID_EGYPTIAN_COTTON_LINEN = 90;
export const PRESET_UNIT_FACILITY_ID_COT_ON_REQUEST = 91;
export const PRESET_UNIT_FACILITY_ID_EN_SUITE = 92;
export const PRESET_UNIT_FACILITY_ID_DESIGNER_TOILETRIES = 93;
export const PRESET_UNIT_FACILITY_ID_BATHROBE_ON_REQUEST = 94;
export const PRESET_UNIT_FACILITY_ID_HEATED_TOWEL_RAILS = 95;
export const PRESET_UNIT_FACILITY_ID_REMOTE_CONTROL_TV = 96;
export const PRESET_UNIT_FACILITY_ID_FLAT_SCREEN_TV = 97;
export const PRESET_UNIT_FACILITY_ID_DVD_LIBRARY = 98;
export const PRESET_UNIT_FACILITY_ID_KETTLE = 99;
export const PRESET_UNIT_FACILITY_ID_ROOM_SERVICE_LIMITED_HOURS = 100;
export const PRESET_UNIT_FACILITY_ID_SAFE_WITH_LAPTOP_CAPACITY = 101;
export const PRESET_UNIT_FACILITY_ID_NEWSPAPER_ON_REQUEST = 102;
export const PRESET_UNIT_FACILITY_ID_IRON_AND_IRONING_BOARD_ON_REQUEST = 103;
export const PRESET_UNIT_FACILITY_ID_FULL_LENGTH_MIRROR = 104;
export const PRESET_UNIT_FACILITY_ID_MAKE_UP_MIRROR = 105;
export const PRESET_UNIT_FACILITY_ID_SHOE_SHINE = 106;
export const PRESET_UNIT_FACILITY_ID_WAKE_UP_CALLS = 107;
export const PRESET_UNIT_FACILITY_ID_DAILY_HOUSEKEEPING = 108;
export const PRESET_UNIT_FACILITY_ID_LATE_CHECK_OUT = 109;
export const PRESET_UNIT_FACILITY_ID_EARLY_CHECK_IN = 110;
export const PRESET_UNIT_FACILITY_ID_WINDOWS_OPEN = 111;
export const PRESET_UNIT_FACILITY_ID_WIFI_FREE = 112;
export const PRESET_UNIT_FACILITY_ID_DIRECT_DIAL_TELEPHONE = 113;
export const PRESET_UNIT_FACILITY_ID_WORK_DESK_WITH_LAMP = 114;
export const PRESET_UNIT_FACILITY_ID_DESK_CHAIR = 115;
export const PRESET_UNIT_FACILITY_ID_HYDROMASSAGE_SHOWER = 116;
export const PRESET_UNIT_FACILITY_ID_RAINFALL_SHOWER = 117;
export const PRESET_UNIT_FACILITY_ID_BOTTLED_WATER = 118;
export const PRESET_UNIT_FACILITY_ID_CROCKERY_AND_UTENSILS_SUPPLIED = 119;
export const PRESET_UNIT_FACILITY_ID_DIGITAL_TELEVISION_CHANNELS = 120;
export const PRESET_UNIT_FACILITY_ID_CABLE_TELEVISION_CHANNELS = 121;
export const PRESET_UNIT_FACILITY_ID_PATIO = 122;
export const PRESET_UNIT_FACILITY_ID_SMART_TV = 123;
export const PRESET_UNIT_FACILITY_ID_BALCONY_PATIO_FURNITURE = 124;
export const PRESET_UNIT_FACILITY_ID_CLOTHES_DRYING_RAIL = 125;
export const PRESET_UNIT_FACILITY_ID_CEILING_FAN = 126;
export const PRESET_UNIT_FACILITY_ID_TOASTER = 127;
export const PRESET_UNIT_FACILITY_ID_COFFEE_MACHINE = 128;
export const PRESET_UNIT_FACILITY_ID_SWIM_UP_POOL = 129;

export interface PresetUnitFacility {
  id: number;
  // The original, formatted and untranslated name
  name: string;
}
