import type { CancellationPolicy } from '@/property/booking-policy/cancellation-policy/cancellation-policy';
import { CancellationPolicyType } from '@/property/booking-policy/cancellation-policy/cancellation-policy';

export const mapCancellationPolicyFromDTO = (
  cancellationPolicyDTO: any,
): CancellationPolicy => {
  const {
    cancellationType,
    minimumNumberOfDaysBeforeArrivalThreshold,
    maximumNumberOfDaysBeforeArrivalThreshold,
  } = cancellationPolicyDTO;

  switch (cancellationType) {
    case CancellationPolicyType.Free:
      return {
        cancellationType,
        minimumNumberOfDaysBeforeArrivalThreshold,
        maximumNumberOfDaysBeforeArrivalThreshold,
      };
    case CancellationPolicyType.Nightly:
      return {
        cancellationType,
        numberOfNights: cancellationPolicyDTO.numberOfNights,
        minimumNumberOfDaysBeforeArrivalThreshold,
        maximumNumberOfDaysBeforeArrivalThreshold,
      };
    case CancellationPolicyType.Monetary:
      return {
        cancellationType,
        amount: Number(cancellationPolicyDTO.amount),
        minimumNumberOfDaysBeforeArrivalThreshold,
        maximumNumberOfDaysBeforeArrivalThreshold,
      };
    case CancellationPolicyType.Percentage:
      return {
        cancellationType,
        percentage: Number(cancellationPolicyDTO.percentage),
        minimumNumberOfDaysBeforeArrivalThreshold,
        maximumNumberOfDaysBeforeArrivalThreshold,
      };
    case CancellationPolicyType.PerUnitMonetary:
      return {
        cancellationType,
        amountPerUnit: Number(cancellationPolicyDTO.amountPerUnit),
        minimumNumberOfDaysBeforeArrivalThreshold,
        maximumNumberOfDaysBeforeArrivalThreshold,
      };
    default:
      throw new Error(
        `Unexpected cancellation type: ${JSON.stringify(cancellationType)}`,
      );
  }
};

export const mapCancellationPoliciesFromDTOs = (
  cancellationPolicyDTOs: any[],
): CancellationPolicy[] =>
  cancellationPolicyDTOs.map(mapCancellationPolicyFromDTO);
