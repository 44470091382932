<template>
  <div class="flex justify-between gap-2 text-sm">
    <div class="capitalize">
      {{ $t('excludedNamedTax', { taxName: bookingVat.vat.name }) }}
    </div>

    <div class="font-semibold">
      {{
        $n(bookingVat.excludedAmount, {
          style: 'currency',
          currency: bookingItineraryStore.property.currencyCode,
        })
      }}
    </div>
  </div>
</template>

<script setup lang="ts">
import type { BookingVat } from '@/booking/booking-taxes/vat/booking-vat';
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';

defineProps<{ bookingVat: BookingVat }>();

const bookingItineraryStore = useBookingItineraryStore();
</script>
