import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import { createMerchantSessionKey } from '@/property/payment-gateway/sage-pay/sage-pay-payment-gateway.api';

export const useSagePayCheckoutStore = defineStore('sage-pay-checkout', () => {
  const { t } = useI18n();

  const bookingItineraryStore = useBookingItineraryStore();

  const isLoadingCheckoutScript = ref(true);
  const isLoadingMerchantSessionKey = ref(true);
  const isRenderingCheckoutForm = ref(false);
  const isCreatingBooking = ref(false);
  const is3DSModalOpen = ref(false);

  const merchantSessionKey = ref('');

  const cardErrorMessage = ref<string>();

  const canRenderCheckoutForm = computed(
    () => !isLoadingCheckoutScript.value && merchantSessionKey.value,
  );

  const showLoadingOverlay = computed(
    () =>
      isLoadingCheckoutScript.value ||
      isLoadingMerchantSessionKey.value ||
      isRenderingCheckoutForm.value,
  );

  const loadMerchantSessionKey = async () => {
    isLoadingMerchantSessionKey.value = true;

    merchantSessionKey.value = await createMerchantSessionKey(
      bookingItineraryStore.property.id,
    );

    isLoadingMerchantSessionKey.value = false;
  };

  const setCardErrorMessageFromSagePayCode = (code: string) => {
    cardErrorMessage.value = mapSagePayCodeToMessage(code);
  };

  const mapSagePayCodeToMessage = (code: string): string => {
    switch (code) {
      case '1008':
      case '3069':
        return t('sorryThatCardTypeIsNotSupported');
      case '2000':
        return t('sorryThisTransactionHasBeenDeclinedByYourBank');
      default:
        return t('sorrySomethingWentWrongWhenProcessingYourCardPleaseTryAgain');
    }
  };

  return {
    isLoadingCheckoutScript,
    isRenderingCheckoutForm,
    isCreatingBooking,
    is3DSModalOpen,
    canRenderCheckoutForm,
    showLoadingOverlay,
    merchantSessionKey,
    cardErrorMessage,
    loadMerchantSessionKey,
    setCardErrorMessageFromSagePayCode,
  };
});
