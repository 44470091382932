import { DayOfWeek } from '@/day-of-week/day-of-week';
import { mapLateBookingThresholdFromDTO } from '@/property/late-booking-threshold/late-booking-threshold.mapper';
import type { LateBookingThresholds } from '@/property/late-booking-threshold/late-booking-thresholds';

export const mapLateBookingThresholdsFromDTO = (
  lateBookingThresholdsDTO: any,
): LateBookingThresholds => ({
  [DayOfWeek.Monday]: mapLateBookingThresholdFromDTO(
    lateBookingThresholdsDTO[DayOfWeek.Monday],
  ),
  [DayOfWeek.Tuesday]: mapLateBookingThresholdFromDTO(
    lateBookingThresholdsDTO[DayOfWeek.Tuesday],
  ),
  [DayOfWeek.Wednesday]: mapLateBookingThresholdFromDTO(
    lateBookingThresholdsDTO[DayOfWeek.Wednesday],
  ),
  [DayOfWeek.Thursday]: mapLateBookingThresholdFromDTO(
    lateBookingThresholdsDTO[DayOfWeek.Thursday],
  ),
  [DayOfWeek.Friday]: mapLateBookingThresholdFromDTO(
    lateBookingThresholdsDTO[DayOfWeek.Friday],
  ),
  [DayOfWeek.Saturday]: mapLateBookingThresholdFromDTO(
    lateBookingThresholdsDTO[DayOfWeek.Saturday],
  ),
  [DayOfWeek.Sunday]: mapLateBookingThresholdFromDTO(
    lateBookingThresholdsDTO[DayOfWeek.Sunday],
  ),
});
