import { mapDiscountOfferMealAdjustmentFromDTO } from '@/property/offer/discount-offer/adjustment/meal-adjustment/discount-offer-meal-adjustment.mapper';
import { mapDiscountOfferUnitAdjustmentFromDTO } from '@/property/offer/discount-offer/adjustment/unit-adjustment/discount-offer-unit-adjustment.mapper';
import type { DiscountOffer } from '@/property/offer/discount-offer/discount-offer';
import { OfferType } from '@/property/offer/offer';

export const mapDiscountOfferFromDTO = (
  discountOfferDTO: any,
): DiscountOffer => ({
  offerType: OfferType.Discount,
  id: discountOfferDTO.id,
  discountType: discountOfferDTO.discountType,
  name: discountOfferDTO.name,
  description: discountOfferDTO.description,
  fromDate: discountOfferDTO.fromDate,
  toDate: discountOfferDTO.toDate,
  applicableStayDaysType: discountOfferDTO.applicableStayDaysType,
  applicableDaysOfWeek: discountOfferDTO.applicableDaysOfWeek,
  isRefundable: discountOfferDTO.isRefundable,
  applicableWayToSellIds: discountOfferDTO.applicableWayToSellIds,
  minimumStayLengthDays: discountOfferDTO.minimumStayLengthDays,
  maximumStayLengthDays: discountOfferDTO.maximumStayLengthDays,
  minimumAdvanceBookingLengthDays:
    discountOfferDTO.minimumAdvanceBookingLengthDays,
  maximumAdvanceBookingLengthDays:
    discountOfferDTO.maximumAdvanceBookingLengthDays,
  unitAdjustments: discountOfferDTO.unitAdjustments.map(
    mapDiscountOfferUnitAdjustmentFromDTO,
  ),
  mealAdjustments: discountOfferDTO.mealAdjustments.map(
    mapDiscountOfferMealAdjustmentFromDTO,
  ),
});
