import type { Address } from '@/address/address';
import type { Image } from '@/image/image';
import type { Occupancy } from '@/occupancy/occupancy';
import type { BookingPolicies } from '@/property/booking-policy/booking-policies/booking-policies';
import type { CheckInPolicy } from '@/property/check-in-policy/check-in-policy';
import type { ChildPolicy } from '@/property/child-policy/child-policy';
import type { Extra } from '@/property/extra/extra';
import type { FacebookPixel } from '@/property/facebook-pixel/facebook-pixel';
import type { GoogleAdwords } from '@/property/google/adwords/google-adwords';
import type { GoogleAnalytics } from '@/property/google/analytics/google-analytics';
import type { GroupsPolicy } from '@/property/groups-policy/groups-policy';
import type { LateBookingThresholds } from '@/property/late-booking-threshold/late-booking-thresholds';
import type { Meal } from '@/property/meal/meal';
import type { Offer } from '@/property/offer/offer';
import type { ParkingPolicies } from '@/property/parking-policy/parking-policies';
import type { PaymentGateway } from '@/property/payment-gateway/payment-gateway';
import type { PetsPolicy } from '@/property/pets-policy/pets-policy';
import type { PropertyTaxes } from '@/property/property-taxes/property-taxes';
import type { PropertyTheme } from '@/property/property-theme/property-theme';
import type { PropertyVanity } from '@/property/property-vanity/property-vanity';
import type { PropertyWidgetSettings } from '@/property/property-widget-settings/property-widget-settings';
import type { SmokingPolicy } from '@/property/smoking-policy/smoking-policy';
import type { UnitType } from '@/property/unit/type/unit-type';
import type { Unit } from '@/property/unit/unit';

export const MAX_INFANT_AGE = 2;

export enum UnitOrderPreference {
  BestFitOccupancyLowestPrice = 'best_price_plan',
  BestFitOccupancyHighestPrice = 'best_fit_occupancy_highest_price',
  UnitPriority = 'unit_priority',
}

export interface Property {
  id: number;
  name: string;
  unitType: UnitType;
  address: Address;
  phoneNumber: string;
  emailAddress: string;
  currencyCode: string;
  timezone: string;
  units: Unit[];
  priorityOrderedUnitIds: number[];
  meals: Meal[];
  offers: Offer[];
  hasAnyPromocodes: boolean;
  hasAnyPrivateRateOverrides: boolean;
  extras: Extra[];
  priorityOrderedExtraIds: number[];
  images: Image[];
  mainImageId: number | undefined;
  childPolicy: ChildPolicy;
  bookingPolicies: BookingPolicies;
  smokingPolicy: SmokingPolicy;
  checkInPolicy: CheckInPolicy;
  parkingPolicies: ParkingPolicies;
  groupsPolicy: GroupsPolicy;
  petsPolicy: PetsPolicy;
  propertyTaxes: PropertyTaxes;
  propertyTheme: PropertyTheme;
  propertyVanity: PropertyVanity | undefined;
  paymentGateway: PaymentGateway;
  widgetSettings: PropertyWidgetSettings;
  defaultSearchOccupancy: Occupancy;
  unitOrderPreference: UnitOrderPreference;
  maxStayLength: number;
  googleAnalytics: GoogleAnalytics | undefined;
  googleAdwords: GoogleAdwords | undefined;
  facebookPixel: FacebookPixel | undefined;
  extendedTermsAndConditions: string;
  lateBookingThresholds: LateBookingThresholds;
  canUseExpressCheckout: boolean;
}
