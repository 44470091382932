import type { ParkingPolicyFreeCharge } from '@/property/parking-policy/charge/free/parking-policy-free-charge';
import type { ParkingPolicyPerDayMonetaryCharge } from '@/property/parking-policy/charge/per-day-monetary/parking-policy-per-day-monetary-charge';

export enum ParkingPolicyChargeType {
  Free = 'free',
  PerDayMonetary = 'per_day_monetary',
}

export type ParkingPolicyCharge =
  | ParkingPolicyFreeCharge
  | ParkingPolicyPerDayMonetaryCharge;
