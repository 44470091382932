import { mapBookingPolicyFromDTO } from '@/property/booking-policy/booking-policy.mapper';
import type { StandardBookingPolicy } from '@/property/booking-policy/standard-booking-policy/standard-booking-policy';

export const mapStandardBookingPolicyFromDTO = (
  standardBookingPolicyDTO: any,
): StandardBookingPolicy => ({
  minimumNumberOfDaysBeforeArrivalThreshold:
    standardBookingPolicyDTO.minimumNumberOfDaysBeforeArrivalThreshold,
  maximumNumberOfDaysBeforeArrivalThreshold:
    standardBookingPolicyDTO.maximumNumberOfDaysBeforeArrivalThreshold,
  bookingPolicy: mapBookingPolicyFromDTO(
    standardBookingPolicyDTO.bookingPolicy,
  ),
});
