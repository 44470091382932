import type { CardPaymentMethodPolicyCard } from '@/property/booking-policy/payment-method-policy/card/card-payment-method-policy-card';
import { mapCardPaymentMethodPolicyCardChargeFromDTO } from '@/property/booking-policy/payment-method-policy/card/charge/card-payment-method-policy-card-charge.mapper';

export const mapCardPaymentMethodPolicyCardFromDTO = (
  cardPaymentMethodPolicyCardDTO: any,
): CardPaymentMethodPolicyCard => ({
  cardType: cardPaymentMethodPolicyCardDTO.cardType,
  charge: mapCardPaymentMethodPolicyCardChargeFromDTO(
    cardPaymentMethodPolicyCardDTO.charge,
  ),
});
