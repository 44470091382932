import { ky } from '@/ky';
import type { Promocode } from '@/property/promocode/promocode';
import { mapPromocodeFromDTO } from '@/property/promocode/promocode.mapper';

export const fetchPropertyPromocode = async (
  promocodeText: string,
  propertyId: number,
  shouldSuppressError = true,
): Promise<Promocode | undefined> =>
  fetchPromocode(
    `property/${propertyId}/promocode/${promocodeText}`,
    shouldSuppressError,
  );

export const fetchWidgetPrimaryPropertyPromocode = async (
  promocodeText: string,
  widgetId: string,
  shouldSuppressError = true,
): Promise<Promocode | undefined> =>
  fetchPromocode(
    `widgets/${widgetId}/primary-property/promocode/${promocodeText}`,
    shouldSuppressError,
  );

/**
 * By default any errors are "suppressed", and undefined is returned. This is
 * often desirable as providing an unknown promocode will result in a 404 HTTP
 * response, which we don't want to cause an error.
 */
const fetchPromocode = async (
  url: string,
  shouldSuppressError: boolean,
): Promise<Promocode | undefined> => {
  try {
    const promocodeDTO = await ky.get(url).json();

    return mapPromocodeFromDTO(promocodeDTO);
  } catch (error) {
    if (shouldSuppressError) {
      return undefined;
    }

    throw error;
  }
};
