import type { GoogleAnalytics } from '@/property/google/analytics/google-analytics';

export const mapGoogleAnalyticsFromDTO = (
  googleAnalyticsDTO: any,
): GoogleAnalytics => ({
  key: googleAnalyticsDTO.analyticsKey,
  version: googleAnalyticsDTO.analyticsVersion,
  isEcommerceEnabled: googleAnalyticsDTO.isEcommerceEnabled,
  sourceDomain: googleAnalyticsDTO.sourceDomain,
});
