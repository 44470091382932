import type {
  BookingExtra,
  BookingExtraQuestion,
} from '@/booking/extra/booking-extra';

export const mapBookingExtraFromDTO = (bookingExtraDTO: any): BookingExtra => ({
  extraName: bookingExtraDTO.extraName,
  itemCharge: Number(bookingExtraDTO.itemCharge),
  quantity: bookingExtraDTO.quantity,
  numberOfNights: bookingExtraDTO.numberOfNights ?? undefined,
  totalCharge: Number(bookingExtraDTO.totalCharge),
  isMandatory: bookingExtraDTO.isMandatory,
  bookingExtraQuestion: bookingExtraDTO.bookingExtraQuestion
    ? mapBookingExtraQuestionFromDTO(bookingExtraDTO.bookingExtraQuestion)
    : undefined,
});

export const mapBookingExtraQuestionFromDTO = (
  bookingExtraQuestionDTO: any,
): BookingExtraQuestion => ({
  question: bookingExtraQuestionDTO.question,
  answer: bookingExtraQuestionDTO.answer ?? undefined,
});
