<template>
  <footer
    class="fixed bottom-0 z-[15] w-full border-t border-gray-300 bg-white"
  >
    <div class="space-y-2 px-4 py-6">
      <BookingSummaryFooterTermsAndConditionsAgreement
        class="mb-2 text-center"
      />

      <div class="flex items-center">
        <div class="capitalize">
          <BookingSummaryFooterTotalRate class="text-lg font-bold" />

          <div class="text-sm">
            {{
              generateCountDisplayText(
                bookingItineraryStore.unitItinerary.map(
                  (unitItineraryItem) => unitItineraryItem.occupancy,
                ),
                bookingItineraryStore.property!.unitType,
              )
            }}
          </div>
        </div>

        <div class="ml-2 flex flex-1 justify-end">
          <BookingSummaryFooterContinueButton />
        </div>
      </div>

      <BookingSummaryFooterRecaptchaInformation class="text-center" />
    </div>

    <div v-show="!bookingSummaryStore.isModalOpen" class="flex justify-center">
      <button
        class="absolute top-0 text-zinc-300 focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-slate-800"
        @click="bookingSummaryStore.isModalOpen = true"
      >
        <IconChevronUp />
      </button>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { IconChevronUp } from '@tabler/icons-vue';
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import { useBookingSummaryStore } from '@/booking-summary/booking-summary.store';
import BookingSummaryFooterContinueButton from '@/booking-summary/footer/BookingSummaryFooterContinueButton.vue';
import BookingSummaryFooterRecaptchaInformation from '@/booking-summary/footer/BookingSummaryFooterRecaptchaInformation.vue';
import BookingSummaryFooterTermsAndConditionsAgreement from '@/booking-summary/footer/BookingSummaryFooterTermsAndConditionsAgreement.vue';
import BookingSummaryFooterTotalRate from '@/booking-summary/footer/BookingSummaryFooterTotalRate.vue';
import { useOccupancy } from '@/occupancy/occupancy.composable';

defineEmits(['click']);

const bookingSummaryStore = useBookingSummaryStore();
const bookingItineraryStore = useBookingItineraryStore();

const { generateCountDisplayText } = useOccupancy();
</script>
