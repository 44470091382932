import type { StandardBookingPolicy } from '@/property/booking-policy/standard-booking-policy/standard-booking-policy';
import type { StayDates } from '@/stay-dates/stay-dates';
import { getNumberOfDaysBetweenNowAndArrival } from '@/stay-dates/stay-dates.utilities';

export const isStandardBookingPolicyApplicableForStayDates = (
  {
    minimumNumberOfDaysBeforeArrivalThreshold,
    maximumNumberOfDaysBeforeArrivalThreshold,
  }: StandardBookingPolicy,
  stayDates: StayDates,
  timezone: string,
): boolean => {
  const numberOfDaysBetweenNowAndArrival = getNumberOfDaysBetweenNowAndArrival(
    stayDates,
    timezone,
  );

  return (
    numberOfDaysBetweenNowAndArrival >=
      minimumNumberOfDaysBeforeArrivalThreshold &&
    (maximumNumberOfDaysBeforeArrivalThreshold === null ||
      numberOfDaysBetweenNowAndArrival <=
        maximumNumberOfDaysBeforeArrivalThreshold)
  );
};
