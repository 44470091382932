import { useEventBus } from '@vueuse/core';
import { HTTPError } from 'ky';
import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import { toast } from 'vue3-toastify';
import { useBookingCreate } from '@/booking/create/booking-create.composable';
import type { BookingCreateError } from '@/booking/create/error/booking-create-error';
import { BookingCreateErrorType } from '@/booking/create/error/booking-create-error';
import { BookingCreateProcessingErrorType } from '@/booking/create/error/processing/booking-create-processing-error';
import { shouldBookingCreateProcessingErrorTriggerAvailabilityError } from '@/booking/create/error/processing/booking-create-processing-error.utilities';
import { useBookingConfirmationNavigation } from '@/booking-confirmation-page/booking-confirmation-navigation.composable';
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import { useBookingStageStore } from '@/booking-stage/booking-stage.store';
import { useBookingSummaryStore } from '@/booking-summary/booking-summary.store';
import { bookingCreateAvailabilityErrorEventBusKey } from '@/event-bus/event-bus';
import { useGuestStore } from '@/guest/guest.store';
import i18n from '@/i18n';
import { IDB_KEY_CONFIRM_EMAIL } from '@/idb';
import { useIDBRef } from '@/idb/idb-ref.composable';
import { logError } from '@/log/log.utilities';
import { PaymentGatewayType } from '@/property/payment-gateway/payment-gateway';
import { RecaptchaAction } from '@/recaptcha/recaptcha';
import { generateRecaptchaToken } from '@/recaptcha/recaptcha.utilities';
import type AppForm from '@/ui/app-form/AppForm.vue';

const { t } = i18n.global;

export const useGuestDetailsStore = defineStore('guest-details', () => {
  const { goToBookingConfirmationWithBookingCreateResult } =
    useBookingConfirmationNavigation();

  const bookingSummaryStore = useBookingSummaryStore();
  const bookingStageStore = useBookingStageStore();
  const bookingItineraryStore = useBookingItineraryStore();
  const guestStore = useGuestStore();

  const form = ref<InstanceType<typeof AppForm> | null>(null);
  const confirmEmail = ref('');

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const isFormValid = computed(() => form.value?.isValid ?? false);

  const goToNextStage = async (shouldCreateBooking = false): Promise<void> => {
    form.value?.validate();

    if (!isFormValid.value) {
      return;
    }

    if (shouldCreateBooking) {
      bookingSummaryStore.isLoading = true;

      try {
        const { createBooking } = useBookingCreate();

        const recaptchaToken = await generateRecaptchaToken(
          RecaptchaAction.CreateBooking,
        );

        const bookingCreateResult = await createBooking({
          type: PaymentGatewayType.None,
          recaptchaToken,
        });

        await goToBookingConfirmationWithBookingCreateResult(
          bookingCreateResult,
        );
      } catch (error) {
        if (error instanceof HTTPError) {
          const responseBody =
            (await error.response.json()) as BookingCreateError;

          if (responseBody.type === BookingCreateErrorType.ProcessingError) {
            if (
              responseBody.error.type ===
              BookingCreateProcessingErrorType.InvalidRecaptchaToken
            ) {
              grecaptcha.reset();
            } else if (
              shouldBookingCreateProcessingErrorTriggerAvailabilityError(
                responseBody.error,
              )
            ) {
              useEventBus(bookingCreateAvailabilityErrorEventBusKey).emit();

              return;
            }
          }
        }

        toast.error(
          t('sorrySomethingWentWrongWhenConfirmingYourBookingPleaseTryAgain'),
          { autoClose: false },
        );

        logError(error as Error);
      } finally {
        bookingSummaryStore.isLoading = false;
      }
    } else {
      bookingStageStore.goToNextStage();
    }
  };

  const watchNameChanges = () => {
    watch(
      () => guestStore.name,
      (name) => {
        if (bookingItineraryStore.unitItinerary[0]) {
          bookingItineraryStore.unitItinerary[0].leadGuestName = name;
        }
      },
    );
  };

  useIDBRef(IDB_KEY_CONFIRM_EMAIL, confirmEmail);

  return {
    form,
    confirmEmail,
    isFormValid,
    goToNextStage,
    watchNameChanges,
  };
});
