import type { DepositPolicy } from '@/property/booking-policy/deposit-policy/deposit-policy';
import { DepositPolicyType } from '@/property/booking-policy/deposit-policy/deposit-policy';
import { mapMandatoryDepositPolicyFromDTO } from '@/property/booking-policy/deposit-policy/mandatory/mandatory-deposit-policy.mapper';
import { mapNoDepositPolicyFromDTO } from '@/property/booking-policy/deposit-policy/no/no-deposit-policy.mapper';

export const mapDepositPolicyFromDTO = (
  depositPolicyDTO: any,
): DepositPolicy =>
  depositPolicyDTO.policyType === DepositPolicyType.Mandatory
    ? mapMandatoryDepositPolicyFromDTO(depositPolicyDTO)
    : mapNoDepositPolicyFromDTO(depositPolicyDTO);
