<template>
  <PropertyCheckInTimeSelector
    v-model="bookingItineraryStore.checkInTime"
    :property="bookingItineraryStore.property"
  />
</template>

<script setup lang="ts">
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import PropertyCheckInTimeSelector from '@/property/check-in-time-selector/PropertyCheckInTimeSelector.vue';

const bookingItineraryStore = useBookingItineraryStore();
</script>
