import { BookingCreateResultType } from '@/booking/create/result/booking-create-result';
import type { BookingCreateSagePay3DSRequiredResult } from '@/booking/create/result/sage-pay-3ds-required/booking-create-sage-pay-3ds-required-result';

export const mapBookingCreateSagePay3DSRequiredResultFromDTO = (
  bookingCreateSagePay3DSRequiredResultDTO: any,
): BookingCreateSagePay3DSRequiredResult => ({
  type: BookingCreateResultType.SagePay3DSRequired,
  vendorTxCode: bookingCreateSagePay3DSRequiredResultDTO.vendorTxCode,
  transactionId: bookingCreateSagePay3DSRequiredResultDTO.transactionId,
  acsUrl: bookingCreateSagePay3DSRequiredResultDTO.acsUrl,
  cReq: bookingCreateSagePay3DSRequiredResultDTO.cReq,
});
