import type { BeforeArrivalAdvancedPaymentPolicy } from '@/property/booking-policy/advanced-payment-policy/before-arrival/before-arrival-advanced-payment-policy';
import type { OnArrivalAdvancedPaymentPolicy } from '@/property/booking-policy/advanced-payment-policy/on-arrival/on-arrival-advanced-payment-policy';
import type { OnDepartureAdvancedPaymentPolicy } from '@/property/booking-policy/advanced-payment-policy/on-departure/on-departure-advanced-payment-policy';

export enum AdvancedPaymentPolicyType {
  OnArrival = 'on_arrival',
  OnDeparture = 'on_departure',
  BeforeArrival = 'before_arrival',
}

export type AdvancedPaymentPolicy =
  | OnArrivalAdvancedPaymentPolicy
  | OnDepartureAdvancedPaymentPolicy
  | BeforeArrivalAdvancedPaymentPolicy;
