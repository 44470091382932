import type { ChildMealRateAdjustment } from '@/property/meal/meal-rate-adjustment/child-meal-rate-adjustment/child-meal-rate-adjustment';
import { mapMealRateAdjustmentFromDTO } from '@/property/meal/meal-rate-adjustment/meal-rate-adjustment.mapper';

export const mapChildMealRateAdjustmentFromDTO = (
  childMealRateAdjustmentDTO: any,
): ChildMealRateAdjustment => ({
  minimumChildAge: childMealRateAdjustmentDTO.minimumChildAge,
  maximumChildAge: childMealRateAdjustmentDTO.maximumChildAge,
  mealRateAdjustment: mapMealRateAdjustmentFromDTO(
    childMealRateAdjustmentDTO.mealRateAdjustment,
  ),
});

export const mapChildMealRateAdjustmentsFromDTOs = (
  childMealRateAdjustmentDTOs: any[],
): ChildMealRateAdjustment[] =>
  childMealRateAdjustmentDTOs.map(mapChildMealRateAdjustmentFromDTO);
