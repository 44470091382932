<template>
  <AppFormValidatedInput
    :value="hasAgreed"
    :validation-rules="[
      isEqualToRule(true, $t('pleaseAgreeToTheTermsAndConditions')),
    ]"
  >
    <AppCheckbox
      v-model="hasAgreed"
      :label-text="$t('iAgreeToTheTermsAndConditions')"
      label-id="terms-and-conditions"
    />
  </AppFormValidatedInput>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import AppCheckbox from '@/ui/app-checkbox/AppCheckbox.vue';
import { isEqualToRule } from '@/ui/app-form/app-form-input-validation-rules.utilities';
import AppFormValidatedInput from '@/ui/app-form/AppFormValidatedInput.vue';

const hasAgreed = ref(false);
</script>
