import type { NonePaymentGateway } from '@/property/payment-gateway/none/none-payment-gateway';
import type { PcibPaymentGateway } from '@/property/payment-gateway/pcib/pcib-payment-gateway';
import type { SagePayPaymentGateway } from '@/property/payment-gateway/sage-pay/sage-pay-payment-gateway';
import type { StripePaymentGateway } from '@/property/payment-gateway/stripe/stripe-payment-gateway';

export enum PaymentGatewayType {
  None = 'none',
  Stripe = 'stripe',
  SagePay = 'sage_pay',
  Pcib = 'pcib',
}

export type PaymentGateway =
  | NonePaymentGateway
  | StripePaymentGateway
  | SagePayPaymentGateway
  | PcibPaymentGateway;
