import type { FreeCancellationPolicy } from '@/property/booking-policy/cancellation-policy/free/free-cancellation-policy';
import type { MonetaryCancellationPolicy } from '@/property/booking-policy/cancellation-policy/monetary/monetary-cancellation-policy';
import type { NightlyCancellationPolicy } from '@/property/booking-policy/cancellation-policy/nightly/nightly-cancellation-policy';
import type { PerUnitMonetaryCancellationPolicy } from '@/property/booking-policy/cancellation-policy/per-unit/per-unit-monetary-cancellation-policy';
import type { PercentageCancellationPolicy } from '@/property/booking-policy/cancellation-policy/percentage/percentage-cancellation-policy';

export enum CancellationPolicyType {
  Free = 'free',
  Nightly = 'nightly',
  Percentage = 'percentage',
  Monetary = 'monetary',
  PerUnitMonetary = 'per_unit_monetary',
}

export type CancellationPolicy =
  | FreeCancellationPolicy
  | MonetaryCancellationPolicy
  | NightlyCancellationPolicy
  | PercentageCancellationPolicy
  | PerUnitMonetaryCancellationPolicy;

export type NonFreeCancellationPolicy = Exclude<
  CancellationPolicy,
  FreeCancellationPolicy
>;

export interface BaseCancellationPolicy {
  minimumNumberOfDaysBeforeArrivalThreshold: number;
  maximumNumberOfDaysBeforeArrivalThreshold: number | null;
}
