import type { CardPaymentMethodPolicyCardFreeCharge } from '@/property/booking-policy/payment-method-policy/card/charge/free/card-payment-method-policy-card-free-charge';
import type { CardPaymentMethodPolicyCardMonetaryCharge } from '@/property/booking-policy/payment-method-policy/card/charge/monetary/card-payment-method-policy-card-monetary-charge';
import type { CardPaymentMethodPolicyCardPercentageCharge } from '@/property/booking-policy/payment-method-policy/card/charge/percentage/card-payment-method-policy-card-percentage-charge';

export enum CardPaymentMethodPolicyCardChargeType {
  Free = 'free',
  Monetary = 'monetary',
  Percentage = 'percentage',
}

export type CardPaymentMethodPolicyCardCharge =
  | CardPaymentMethodPolicyCardFreeCharge
  | CardPaymentMethodPolicyCardMonetaryCharge
  | CardPaymentMethodPolicyCardPercentageCharge;
