import { subtractDays } from '@/date/date.utilities';
import type { CancellationPolicy } from '@/property/booking-policy/cancellation-policy/cancellation-policy';
import type { StayDates } from '@/stay-dates/stay-dates';
import { getNumberOfDaysBetweenNowAndArrival } from '@/stay-dates/stay-dates.utilities';

export const getCancellationPolicyStartDate = (
  { maximumNumberOfDaysBeforeArrivalThreshold }: CancellationPolicy,
  checkInDate: string,
): string | undefined =>
  maximumNumberOfDaysBeforeArrivalThreshold !== null
    ? subtractDays(checkInDate, maximumNumberOfDaysBeforeArrivalThreshold)
    : undefined;

export const getCancellationPolicyEndDate = (
  { minimumNumberOfDaysBeforeArrivalThreshold }: CancellationPolicy,
  checkInDate: string,
): string =>
  subtractDays(checkInDate, minimumNumberOfDaysBeforeArrivalThreshold);

export const isCancellationPolicyApplicableForStayDates = (
  cancellationPolicy: CancellationPolicy,
  stayDates: StayDates,
  timezone: string,
): boolean => {
  const numberOfDaysBeforeArrival = getNumberOfDaysBetweenNowAndArrival(
    stayDates,
    timezone,
  );

  return isNumberOfDaysBeforeArrivalInsideCancellationPolicyThreshold(
    cancellationPolicy,
    numberOfDaysBeforeArrival,
  );
};

const isNumberOfDaysBeforeArrivalInsideCancellationPolicyThreshold = (
  cancellationPolicy: CancellationPolicy,
  numberOfDaysBeforeArrival: number,
): boolean =>
  isNumberOfDaysBeforeArrivalInsideCancellationPolicyMinimumThreshold(
    cancellationPolicy,
    numberOfDaysBeforeArrival,
  ) &&
  isNumberOfDaysBeforeArrivalInsideCancellationPolicyMaximumThreshold(
    cancellationPolicy,
    numberOfDaysBeforeArrival,
  );

const isNumberOfDaysBeforeArrivalInsideCancellationPolicyMinimumThreshold = (
  { minimumNumberOfDaysBeforeArrivalThreshold }: CancellationPolicy,
  numberOfDaysBeforeArrival: number,
): boolean =>
  numberOfDaysBeforeArrival >= minimumNumberOfDaysBeforeArrivalThreshold;

const isNumberOfDaysBeforeArrivalInsideCancellationPolicyMaximumThreshold = (
  { maximumNumberOfDaysBeforeArrivalThreshold }: CancellationPolicy,
  numberOfDaysBeforeArrival: number,
): boolean =>
  maximumNumberOfDaysBeforeArrivalThreshold === null ||
  numberOfDaysBeforeArrival <= maximumNumberOfDaysBeforeArrivalThreshold;
