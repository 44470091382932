import { mapAdvancedPaymentPolicyFromDTO } from '@/property/booking-policy/advanced-payment-policy/advanced-payment-policy.mapper';
import type { BookingPolicy } from '@/property/booking-policy/booking-policy';
import { mapCancellationPoliciesFromDTOs } from '@/property/booking-policy/cancellation-policy/cancellation-policy.mapper';
import { mapDepositPolicyFromDTO } from '@/property/booking-policy/deposit-policy/deposit-policy.mapper';
import { mapNoShowPolicyFromDTO } from '@/property/booking-policy/no-show-policy/no-show-policy.mapper';
import { mapPaymentMethodPolicyFromDTO } from '@/property/booking-policy/payment-method-policy/payment-method-policy.mapper';

export const mapBookingPolicyFromDTO = (
  bookingPolicyDTO: any,
): BookingPolicy => ({
  paymentMethodPolicies: bookingPolicyDTO.paymentMethodPolicies.map(
    mapPaymentMethodPolicyFromDTO,
  ),
  depositPolicy: mapDepositPolicyFromDTO(bookingPolicyDTO.depositPolicy),
  advancedPaymentPolicy: bookingPolicyDTO.advancedPaymentPolicy
    ? mapAdvancedPaymentPolicyFromDTO(bookingPolicyDTO.advancedPaymentPolicy)
    : undefined,
  cancellationPolicies: mapCancellationPoliciesFromDTOs(
    bookingPolicyDTO.cancellationPolicies,
  ),
  noShowPolicy: mapNoShowPolicyFromDTO(bookingPolicyDTO.noShowPolicy),
  additionalCancellationConditions:
    bookingPolicyDTO.additionalCancellationConditions,
});
