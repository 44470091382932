<template>
  <div class="text-sm">
    <div
      v-if="
        additionalTaxRate.inclusionType ===
        AdditionalTaxRateInclusionType.Include
      "
      class="break-anywhere first-letter:capitalize"
    >
      {{
        $t('includesNamedTaxOfAmount', {
          taxName: useAdditionalTaxNameDisplayText(additionalTax).value,
          amount: generateAmountDisplayText(bookingAdditionalTax.amount),
        })
      }}
    </div>

    <div
      v-else-if="
        additionalTaxRate.inclusionType ===
        AdditionalTaxRateInclusionType.Exclude
      "
      class="break-anywhere first-letter:capitalize"
    >
      {{
        $t('excludesNamedTaxOfAmount', {
          taxName: useAdditionalTaxNameDisplayText(additionalTax).value,
          amount: generateAmountDisplayText(bookingAdditionalTax.amount),
        })
      }}
    </div>

    <div
      v-else-if="
        additionalTaxRate.inclusionType ===
        AdditionalTaxRateInclusionType.IncludeInvoiceOnly
      "
      class="flex justify-between gap-2"
    >
      <div class="break-anywhere first-letter:capitalize">
        {{
          $t('excludedNamedTax', {
            taxName: useAdditionalTaxNameDisplayText(additionalTax).value,
          })
        }}
      </div>

      <div class="font-semibold">
        {{ generateAmountDisplayText(bookingAdditionalTax.amount) }}
      </div>
    </div>

    <div class="text-neutral-500">({{ additionalTaxRateDisplayText }})</div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import type { BookingAdditionalTax } from '@/booking/booking-taxes/additional/booking-additional-tax';
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import { useAdditionalTaxNameDisplayText } from '@/tax/additional/name-display-text/additional-tax-name-display-text.composable';
import { AdditionalTaxRateInclusionType } from '@/tax/additional/rate/additional-tax-rate';
import { useAdditionalTaxRateDisplayText } from '@/tax/additional/rate/display-text/additional-tax-rate-display-text.composable';

const props = defineProps<{ bookingAdditionalTax: BookingAdditionalTax }>();

const { n } = useI18n();

const bookingItineraryStore = useBookingItineraryStore();

const { property } = storeToRefs(bookingItineraryStore);

const additionalTax = computed(() => props.bookingAdditionalTax.tax);

const additionalTaxRate = computed(() => additionalTax.value.rate);

const additionalTaxRateDisplayText = useAdditionalTaxRateDisplayText(
  additionalTaxRate.value,
  property,
);

const generateAmountDisplayText = (amount: number): string =>
  n(amount, {
    style: 'currency',
    currency: property.value.currencyCode,
  });
</script>
