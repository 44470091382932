import type { DescriptivePromocode } from '@/property/promocode/descriptive-promocode/descriptive-promocode';
import type { MonetaryPromocode } from '@/property/promocode/monetary-promocode/monetary-promocode';
import type { PercentagePromocode } from '@/property/promocode/percentage-promocode/percentage-promocode';

export enum PromocodeType {
  Percentage = 'percentage',
  Monetary = 'monetary',
  Descriptive = 'descriptive',
}

export type Promocode =
  | PercentagePromocode
  | MonetaryPromocode
  | DescriptivePromocode;

export interface BasePromocode {
  readonly promocodeType: PromocodeType;
  promocodeText: string;
  description: string;
  fromDate: string;
  toDate: string;
  minimumStayLengthDays: number;
}
