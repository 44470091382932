import type { ExtraDateRange } from '@/property/extra/date-range/extra-date-range';
import type { ExtraTax } from '@/property/extra/tax/extra-tax';

export enum ExtraChargeType {
  Single = 'single',
  AllNightsOfStay = 'all_nights_of_stay',
  SelectNightsOfStay = 'select_nights_of_stay',
}

export interface SelectableNightExtra extends NonMandatoryExtra {
  readonly chargeType: ExtraChargeType.SelectNightsOfStay;
}

export interface NonSelectableNightExtra extends BaseExtra {
  readonly chargeType: Exclude<
    ExtraChargeType,
    ExtraChargeType.SelectNightsOfStay
  >;
}

export interface BaseExtra {
  id: number;
  name: string;
  description: string;
  charge: number;
  isMandatory: boolean;
  question: string;
  dateRange: ExtraDateRange | undefined;
  tax: ExtraTax | undefined;
}

export interface MandatoryExtra extends BaseExtra {
  isMandatory: true;
  chargeType: ExtraChargeType.AllNightsOfStay | ExtraChargeType.Single;
}

export interface NonMandatoryExtra extends BaseExtra {
  isMandatory: false;
  chargeType: ExtraChargeType;
}

export type Extra = MandatoryExtra | NonMandatoryExtra;
