<template>
  <AvailabilityCalendarModal
    :is-open="isAvailabilityCalendarModalOpen"
    :check-in-date="searchStore.stayDates.checkInDate"
    :check-out-date="searchStore.stayDates.checkOutDate"
    :auto-select-stay-dates="false"
    :button-text="capitalize($t('search'))"
    :icon="IconSearch"
    @close="isAvailabilityCalendarModalOpen = false"
    @submit="onSubmit"
  />

  <UnitSelectionUnitButton
    v-if="shouldUseModal"
    :button-text="toTitleCase($t('showAvailableDates'))"
    @click="isAvailabilityCalendarModalOpen = true"
  />

  <AppPopover v-else v-slot="{ close }">
    <UnitSelectionUnitButton
      ref="popoverButton"
      :button-text="toTitleCase($t('showAvailableDates'))"
      :use-as-popover-button="true"
    />

    <div
      ref="popoverPanel"
      class="z-50 flex scroll-mt-[calc(theme(spacing.inline-booking-stage-selector-height)+theme(spacing.unit-selection-search-bar-height))] justify-center"
      :style="floatingStyles"
    >
      <AppPopoverPanel>
        <AvailabilityCalendarContainer
          :check-in-date="searchStore.stayDates.checkInDate"
          :check-out-date="searchStore.stayDates.checkOutDate"
          :auto-select-stay-dates="false"
          @vue:mounted="scrollPopoverPanelIntoView"
        />

        <template #footer>
          <AvailabilityCalendarFooter
            :button-text="capitalize($t('search'))"
            :icon="IconSearch"
            @submit="
              () => {
                close();
                onSubmit();
              }
            "
          />
        </template>
      </AppPopoverPanel>
    </div>
  </AppPopover>
</template>

<script setup lang="ts">
import { useFloating, autoUpdate, shift } from '@floating-ui/vue';
import { IconSearch } from '@tabler/icons-vue';
import { useBreakpoints, breakpointsTailwind } from '@vueuse/core';
import type { Ref } from 'vue';
import { computed, ref } from 'vue';
import { useProvideAvailabilityCalendarStore } from '@/availability-calendar/availability-calendar.store';
import AvailabilityCalendarContainer from '@/availability-calendar/AvailabilityCalendarContainer.vue';
import AvailabilityCalendarFooter from '@/availability-calendar/AvailabilityCalendarFooter.vue';
import AvailabilityCalendarModal from '@/availability-calendar/modal/AvailabilityCalendarModal.vue';
import { useSearchStore } from '@/search/search.store';
import { useLocaleString } from '@/string/locale-string.composable';
import AppPopover from '@/ui/app-popover/AppPopover.vue';
import AppPopoverPanel from '@/ui/app-popover/AppPopoverPanel.vue';
import UnitSelectionUnitButton from '@/unit-selection-page/unit/calendar-button/UnitSelectionUnitButton.vue';
import { useUnitSelectionStore } from '@/unit-selection-page/unit-selection.store';

const props = defineProps<{ unitId: number }>();

const searchStore = useSearchStore();
const unitSelectionStore = useUnitSelectionStore();

const { toTitleCase, capitalize } = useLocaleString();
const shouldUseModal = useBreakpoints(breakpointsTailwind).smaller('md');

const { selectedStayDates } = useProvideAvailabilityCalendarStore({
  property: computed(() => searchStore.activeProperty),
  unitIds: computed(() => [props.unitId]),
});

const isAvailabilityCalendarModalOpen = ref(false);

const onSubmit = () => {
  isAvailabilityCalendarModalOpen.value = false;

  void unitSelectionStore.updateStayDates(
    selectedStayDates.value!,
    props.unitId,
  );
};

const popoverButton = ref<HTMLElement | null>(null);
const popoverPanel: Ref<HTMLElement | null> = ref(null);

const scrollPopoverPanelIntoView = () => {
  setTimeout(() => {
    popoverPanel.value?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
  });
};

const { floatingStyles } = useFloating(popoverButton, popoverPanel, {
  placement: 'top',
  middleware: [shift({ padding: 15 })],
  whileElementsMounted: autoUpdate,
});
</script>
