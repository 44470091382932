<template>
  <AppLayout :property="searchStore.activeProperty">
    <VanityImageContainer />

    <VanitySearch />
  </AppLayout>
</template>

<script setup lang="ts">
import { useHead } from '@unhead/vue';
import { useElementsStore } from '@/elements/elements.store';
import AppLayout from '@/layouts/AppLayout.vue';
import { FacebookPixelPageViewEventAction } from '@/property/facebook-pixel/facebook-pixel-event-action';
import { useFacebookPixelStore } from '@/property/facebook-pixel/facebook-pixel.store';
import { GoogleAnalyticsPageViewEventAction } from '@/property/google/analytics/google-analytics-event-action';
import { useGoogleAnalyticsStore } from '@/property/google/analytics/google-analytics.store';
import { VANITY_ROUTE } from '@/router';
import { useSearchStore } from '@/search/search.store';
import { UserEventActionLabel } from '@/user-event/user-event';
import { useUserEventStore } from '@/user-event/user-event.store';
import VanityImageContainer from '@/vanity-page/image/VanityImageContainer.vue';
import VanitySearch from '@/vanity-page/search/VanitySearch.vue';

const searchStore = useSearchStore();
const elementsStore = useElementsStore();

const handlePageViewEvents = () => {
  const { trackUserEventAction } = useUserEventStore();
  const { emitGAPageViewEvent } = useGoogleAnalyticsStore();
  const { emitPixelPageViewEvent } = useFacebookPixelStore();

  trackUserEventAction({
    label: UserEventActionLabel.PageView,
    metadata: {
      page_name: VANITY_ROUTE,
      widget_id: searchStore.widget.id,
      property_id: searchStore.activePropertyId,
      is_express_checkout: searchStore.activePropertyCanUseExpressCheckout,
      used_elements: elementsStore.usedElements,
    },
  });

  void emitGAPageViewEvent(
    GoogleAnalyticsPageViewEventAction.VanityPage,
    searchStore.activeProperty.googleAnalytics,
  );

  emitPixelPageViewEvent(
    FacebookPixelPageViewEventAction.VanityPage,
    searchStore.primaryProperty.facebookPixel,
  );
};

handlePageViewEvents();

useHead({ title: () => searchStore.activeProperty.name });
</script>
