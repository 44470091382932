<template>
  <AppModal class="z-30" :is-open="isOpen" @close="$emit('close')">
    <AppModalDialog class="sm:max-w-2xl" @close="$emit('close')">
      <template #header>
        <span class="align-middle font-semibold capitalize leading-none">
          {{ $t('removeLastUnit') }}
        </span>
      </template>

      {{
        $t('youAreAboutToRemoveTheLastUnit', { unitTypeName, unitTypeGender })
      }}

      <template #footer>
        <BookingSummaryBodyUnitItemRemovingLastUnitWarningFooter
          :unit-itinerary-item-id="unitItineraryItemId"
          @close="$emit('close')"
        />
      </template>
    </AppModalDialog>
  </AppModal>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import BookingSummaryBodyUnitItemRemovingLastUnitWarningFooter from '@/booking-summary/body/unit-item/remove-button/BookingSummaryBodyUnitItemRemovingLastUnitWarningFooter.vue';
import { useUnitType } from '@/property/unit/type/unit-type.composable';
import AppModal from '@/ui/app-modal/AppModal.vue';
import AppModalDialog from '@/ui/app-modal/AppModalDialog.vue';

defineProps<{
  unitItineraryItemId: string;
  isOpen: boolean;
}>();

const bookingItineraryStore = useBookingItineraryStore();
const { unitTypeName, unitTypeGender } = useUnitType(
  computed(() => bookingItineraryStore.property.unitType),
);

defineEmits(['close']);
</script>
