import type { NoShowPolicyFreeCharge } from '@/property/booking-policy/no-show-policy/charge/free/no-show-policy-free-charge';
import type { NoShowPolicyFullCharge } from '@/property/booking-policy/no-show-policy/charge/full/no-show-policy-full-charge';
import type { NoShowPolicyMonetaryCharge } from '@/property/booking-policy/no-show-policy/charge/monetary/no-show-policy-monetary-charge';
import type { NoShowPolicyNightlyCharge } from '@/property/booking-policy/no-show-policy/charge/nightly/no-show-policy-nightly-charge';
import type { NoShowPolicyPerUnitMonetaryCharge } from '@/property/booking-policy/no-show-policy/charge/per-unit-monetary/no-show-policy-per-unit-monetary-charge';
import type { NoShowPolicyPercentageCharge } from '@/property/booking-policy/no-show-policy/charge/percentage/no-show-policy-percentage-charge';

export enum NoShowPolicyChargeType {
  Free = 'free',
  Full = 'full',
  Nightly = 'nightly',
  Percentage = 'percentage',
  Monetary = 'monetary',
  PerUnitMonetary = 'per_unit_monetary',
}

export type NoShowPolicyCharge =
  | NoShowPolicyFreeCharge
  | NoShowPolicyFullCharge
  | NoShowPolicyMonetaryCharge
  | NoShowPolicyPercentageCharge
  | NoShowPolicyNightlyCharge
  | NoShowPolicyPerUnitMonetaryCharge;
