import { IconExclamationCircle, IconInfoCircle } from '@tabler/icons-vue';
import type { App } from 'vue';
import { defineComponent, getCurrentInstance } from 'vue';
import Vue3Toastify, { toast, type ToastContainerOptions } from 'vue3-toastify';
import AppCloseButton from '@/ui/app-close-button/AppCloseButton.vue';
import '@/toast/index.css';

export const generateToastOptions = (app: App): ToastContainerOptions => ({
  position: toast.POSITION.BOTTOM_CENTER,
  transition: toast.TRANSITIONS.SLIDE,
  closeButton: ({ closeToast }) => {
    const CloseButton = defineComponent({
      setup() {
        const currentApp = getCurrentInstance();

        if (currentApp) {
          currentApp.appContext.config = app.config;
        }

        return () => (
          <AppCloseButton size={20} class="self-start" onClose={closeToast} />
        );
      },
    });

    return <CloseButton />;
  },
  clearOnUrlChange: false,
});

const toastError = toast.error.bind(this);
toast.error = (content, options) =>
  toastError(content, {
    // @ts-expect-error: TS 2786 due to incorrect library definition
    icon: <IconExclamationCircle size={24} />,
    ...options,
  });

const toastInfo = toast.info.bind(this);
toast.info = (content, options) =>
  toastInfo(content, {
    // @ts-expect-error: TS 2786 due to incorrect library definition
    icon: <IconInfoCircle size={24} />,
    ...options,
  });

export default Vue3Toastify;
