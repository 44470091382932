<template>
  <button
    class="flex items-center focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:text-neutral-400 ui-active:rounded-sm ui-active:outline ui-active:outline-2 ui-active:outline-offset-2"
    :class="
      isCurrentStage || isCompleteStage
        ? 'text-blue-600 focus-visible:outline-blue-600'
        : 'focus-visible:outline-slate-800'
    "
    :disabled="!stage.canAccess"
    @click="goToStage(stage)"
  >
    <IconCheck v-if="isCompleteStage" :stroke-width="2.25" />

    <Component :is="stage.icon" v-else :stroke-width="2" />

    <Component
      :is="isCurrentStage ? 'h1' : 'span'"
      :aria-label="stage.title"
      class="prevent-semibold-layout-shift ml-2 whitespace-nowrap pr-0.5 font-medium capitalize"
      :class="{ 'font-semibold': isCurrentStage }"
    >
      {{ stage.title }}
    </Component>
  </button>
</template>

<script setup lang="ts">
import { IconCheck } from '@tabler/icons-vue';
import { computed } from 'vue';
import type { BookingStage } from '@/booking-stage/booking-stage';
import { useBookingStageStore } from '@/booking-stage/booking-stage.store';

const props = defineProps<{ stage: BookingStage }>();

const { isStageCurrent, isStageComplete, goToStage } = useBookingStageStore();

const isCurrentStage = computed(() => isStageCurrent(props.stage));

const isCompleteStage = computed(() => isStageComplete(props.stage));
</script>
