import type { IncludedMeal } from '@/property/meal/included-meal/included-meal';
import type { SupplementalMeal } from '@/property/meal/supplemental-meal/supplemental-meal';

export enum MealType {
  Breakfast = 'breakfast',
  Lunch = 'lunch',
  Dinner = 'dinner',
}

export interface BaseMeal {
  type: MealType;
  name: string;
}

export type Meal = IncludedMeal | SupplementalMeal;
